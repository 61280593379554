import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton, Stack, Typography, useMediaQuery, Button } from '@mui/material';

// third party
import { useSelector, useDispatch } from 'react-redux';

// project import
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import { drawerWidth } from 'config.js';
import * as actionTypes from 'store/actions';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Link } from 'react-router-dom';
// import { Link } from '@mui/material/Link';

import { useLocation } from 'react-router-dom';
import DatePickerComp from 'views/Insights/DatePicker';
import FilterComponent from 'views/Insights/FilterOption';
import InfoIcon from '@mui/icons-material/Info';
// import { TopNavItems } from 'top-nav-items';
// import topNavItems from 'top-nav-items';
// import logo from 'assets/images/logo.svg';
// import NeophyteLogo from "assets/images/neophyte_logo.png"

// ==============================|| HEADER ||============================== //
// import { driverObj } from 'driverConfig';
// import '../../../drivercss.css';
// driverObj.drive();
import { driverJsInit, driverJsInitStores, driverJsInitTeams } from 'driverConfig';

const Header = ({ drawerToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  // const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  let location = useLocation();
  //eslint-disable-next-line
  //eslint-disable-next-line
  const [filterOptions, setFilterOptions] = useState({});
  const isFilrtreActive = JSON.parse(localStorage.getItem('userData')).user_role === 'NHQ';
  const isLayoutOpen = useSelector((state) => state.customization.isLayoutOpen);
  // const showDatePicker = (location.pathname === '/main/insights') ||
  //                       (/^\/main\/stores\/layout\/[^/]+$/.test(location.pathname) && isLayoutOpen) ||
  //                       (/^\/main\/stores\/storeinsight\/overview\/[^/]+$/.test(location.pathname));
  const hideFilter = /^\/main\/stores\/storeinsight\/overview\/[^/]+$/.test(location.pathname);

  console.log('IS_LAYOUT_OPEN', isLayoutOpen);
  if (window.location.pathname === '/main/insights') {
    // Your code for when the user is on the insights page
    console.log('You are on the insights page');
  }
  const ftypeget = useSelector((state) => state.customization.flType);
  console.log('all filter data is', ftypeget);
  return (
    <>
      <Box width={drawerWidth}>
        <div className="flex">
          <div className="hover:border-customAll  hover:text-customAll-dark border-customAll  text-customAll-dark"></div>
          <div className="hover:border-customBlush  hover:text-customBlush-dark border-customBlush  text-customBlush-dark"></div>
          <div className="hover:border-customLace  hover:text-customLace-dark border-customLace  text-customLace-dark"></div>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box mt={0.5}>
                <Typography
                  onClick={() => window.open('/main/insights', '_self')}
                  variant="h2"
                  align="left"
                  className="black cursor-pointer"
                  sx={{ width: '100%', paddingLeft: 1.6 }}
                >
                  Disha
                </Typography>
                {/* <img className='w-[80%]' src={NeophyteLogo} alt="Logo" /> */}
              </Box>
            </Grid>
          </Box>
          <Grid sx={{ display: { xs: 'block', md: 'none' } }} item>
            <IconButton
              className="text-black ml-0.5"
              edge="start"
              sx={{ mr: theme.spacing(1.25), color: 'white' }}
              // color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid>
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box className="space-x-8" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
          <Link
            to={'/main/insights'}
            onClick={() => dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'insights' })}
            // className={`text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark ${
            //   location.pathname === '/main/insights' ? `border-b-2 border-custom${ftypeget}  text-custom${ftypeget}-dark` : ''
            // }`}
            className={
              location.pathname === '/main/insights'
                ? `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark 
               border-b-2 border-custom${ftypeget}  text-custom${ftypeget}-dark`
                : `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark`
            }
          >
            Insights
          </Link>

          <Link
            to={'/main/stores'}
            onClick={() => dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'customers' })}
            // className={`text-lg hover:border-b-2  hover:border-customAll hover:text-customAll-dark ${
            //   location.pathname.startsWith('/main/stores') ? 'border-b-2 border-customAll text-customAll-dark' : ''
            // }`}
            className={
              location.pathname === '/main/stores'
                ? `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark 
            border-b-2 border-custom${ftypeget}  text-custom${ftypeget}-dark`
                : `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark`
            }
          >
            Stores
          </Link>
          <Link
            to={'/main/team'}
            onClick={() => dispatch({ type: actionTypes.MENU_OPEN, isOpen: 'team' })}
            // className={`text-lg hover:border-b-2  hover:border-customBlush hover:text-customBlush-dark ${
            //   location.pathname === '/main/team' ? 'border-b-2 border-customBlush text-customBlush-dark' : ''
            // }`}
            className={
              location.pathname === '/main/team'
                ? `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark 
            border-b-2 border-custom${ftypeget}  text-custom${ftypeget}-dark`
                : `text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark`
            }
          >
            Teams
          </Link>
          <a
            href="https://neophyte.ai/contact"
            className={`text-lg hover:border-b-2 hover:border-custom${ftypeget} hover:text-custom${ftypeget}-dark`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
          <Button
            variant="outlined"
            onClick={() => {
              console.log('yes');

              if (window.location.pathname === '/main/insights') {
                localStorage.setItem('product_tour_seen', 'false');
                driverJsInit();
              }
              if (window.location.pathname === '/main/stores') {
                localStorage.setItem('product_tour_seen_stores', 'false');
                driverJsInitStores();
              }
              if (window.location.pathname === '/main/team') {
                localStorage.setItem('product_tour_seen_teams', 'false');
                driverJsInitTeams();
              }
            }}
          >
            <Typography>Start Tour</Typography>
            <span className="px-2">
              {' '}
              <InfoIcon />
            </span>
          </Button>
          {/* {TopNavItems.map((item) => {
            <Link className='' key={item.title} to={item.url}>
              {item.title}
            </Link>;
          })} */}
        </Box>
      </Box>
      {/* <SearchSection theme="light" /> */}
      {/* <NotificationSection /> */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          gap: isMobile ? 2 : 2
        }}
      >
        {isFilrtreActive && !hideFilter && (
          <Box sx={{ marginBottom: isMobile ? 2 : 0 }} className="filter">
            {' '}
            {/* Adjust margin for mobile view */}
            <FilterComponent setFilterOptions={setFilterOptions} />
          </Box>
        )}

        <Box sx={{ marginBottom: isMobile ? 2 : 0, position: 'relative' }} className="calendar">
          {' '}
          {/* Adjust margin for mobile view */}
          {/* {!showDatePicker && (
             <Tooltip title={'calender is not active for this page'}> <div className='cursor-not-allowed' style={{position:"absolute", height:"50px", width:'230px',zIndex:"10000", top:'-5px'}}></div></Tooltip>
            )}  */}
          <DatePickerComp />
        </Box>

        <Box className="profile">
          <ProfileSection />
        </Box>
      </Stack>
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;
