import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
// import { footfallGraph } from 'api/sentinelAPI';
import { GetSevenDaysCapProgress, GetSevenDaysOSA, GetBayWiseVM } from 'api';
// import Bubbledxaxis from './Bubbledx-axis';
// import {footfallGraph} from "api/sentinelAPI";
// const footfalldata=footfallGraph();

const Areachart = ({ dates }) => {
  const [category, setCategory] = useState([]);
  // const [setCustCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [capProgress, setCapProgress] = useState(false);
  const [osa, setOsa] = useState(false);
  const [vm, setVM] = useState(false);

  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;

  useEffect(() => {
    async function getData4() {
      const body = {
        dates: dates,
        store_id: analysisStoreId
      };
      try {
        const response = await GetSevenDaysCapProgress(body);
        const data = response.data;
        // console.log('cap', data);
        setLoading(true);
        if (data) {
          const catagorydata = data.map((d) => d.date);
          const custdata = data.map((d) => parseFloat(d.capture_percentage).toFixed(1));
          setCapProgress(custdata);
          setCategory(catagorydata);
          setLoading(false);
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData4();
  }, [dates, analysisStoreId]);

  useEffect(() => {
    async function getData2() {
      const body = {
        dates: dates,
        store_id: analysisStoreId
      };
      try {
        setLoading(true);
        const response = await GetSevenDaysOSA(body);
        const data = response.data;
        console.log('osa', data);
        setLoading(true);
        if (data) {
          const catagorydata = data.map((d) => d.date);
          const custdata = data.map((d) => parseFloat(d.averageFullness).toFixed(1));
          setOsa(custdata);
          setCategory(catagorydata);
          setLoading(false);
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData2();
  }, [dates, analysisStoreId]);

  useEffect(() => {
    async function getData() {
      const body = {
        dates: dates,
        store_id: analysisStoreId
      };
      try {
        setLoading(true);
        const response = await GetBayWiseVM(body);
        const data = response.data;
        console.log('vmmmm', data);
        setLoading(true);
        if (data) {
          console.log('entered data');
          const catagorydata = data.map((d) => d.date);
          const custdata = data.map((d) => parseFloat(d.avgVmScore).toFixed(1));
          setVM(custdata);
          setCategory(catagorydata);
          setLoading(false);
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, [dates, analysisStoreId]);
  //graph options start

  const state = {
    series: [
      {
        name: 'Capture Progress',
        data: capProgress
        // [4, 7, 4, 20, 18, 80, 100,40, 60,30, 20, 33, 15,9, 4 ]
      },
      {
        name: 'OSA score',
        data: osa
        // [4, 7, 4, 20, 18, 80, 100,40, 60,30, 20, 33, 15,9, 4 ]
      },
      {
        name: 'VM score',
        data: vm
        // [4, 7, 4, 20, 18, 80, 100,40, 60,30, 20, 33, 15,9, 4 ]
      }
      //     , {
      //       name: 'Male',
      //       data: [3, 6, 2, 12, 10, 52, 41,80,40, 85, 56,18,20,9,4]
      //     },
      // {

      //         name: 'Female',
      //         data: [2, 4, 3, 10, 9, 62, 51,40, 20 ,18, 15,10,6,4,2]

      // }
    ],
    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false
        }
      },
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 0,
            fillColor: status[0] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 1,
            fillColor: status[1] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: status[2] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 3,
            fillColor: status[3] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 4,
            fillColor: status[4] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: status[5] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 0,
            dataPointIndex: 6,
            fillColor: status[6] ? '#33C393' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 0,
            fillColor: status[0] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 1,
            fillColor: status[1] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: status[2] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 3,
            fillColor: status[3] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 4,
            fillColor: status[4] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: status[5] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 1,
            dataPointIndex: 6,
            fillColor: status[6] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 0,
            fillColor: status[0] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 1,
            fillColor: status[1] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: status[2] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 3,
            fillColor: status[3] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 4,
            fillColor: status[4] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: status[5] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          },
          {
            seriesIndex: 2,
            dataPointIndex: 6,
            fillColor: status[6] ? '#2BC0DA' : '#dadada',
            strokeColor: 'white',
            size: 12
          }
        ]
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      legend: {
        show: true, // Ensure legend is visible
        position: 'top', // Set position to 'top'
        horizontalAlign: 'center',
        // customLegendItems: ['Capture Percentage', 'OSA Score'],
        showForSingleSeries: true
        // ... other legend options
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 15,
        categories: category,
        // ["2018-09-19T15:30:00.000Z", "2018-09-19T16:00:00.000Z", "2018-09-19T16:30:00.000Z", "2018-09-19T17:00:00.000Z", "2018-09-19T17:30:00.000Z", "2018-09-19T18:00:00.000Z", "2018-09-19T18:30:00.000Z",  "2018-09-19T19:00:00.000Z",  "2018-09-19T19:30:00.000Z",  "2018-09-19T20:00:00.000Z",  "2018-09-19T20:30:00.000Z",  "2018-09-19T21:00:00.000Z",  "2018-09-19T21:30:00.000Z",  "2018-09-19T22:00:00.000Z",  "2018-09-19T22:30:00.000Z"],
        labels: {
          show: true // Display all labels on the X-axis
          // rotate: -45, // Rotate labels to 0 degrees
          // showDuplicates: true,
        }
      },
      tooltip: {
        // enabled:false,
        x: {
          format: 'dd/MM/yy HH:mm'
        }
      },
      // toolbar: {
      //   show: false, // Set to false to hide the toolbar/menu items above the chart
      // },
      yaxis: {
        type: 'Number'
        // categories:['0','5','10','15','20','25','30','35','40','45','50','55','60','65','70','75','80','85','90','95','100']
      },
      // colors: ['#37c6fa']
      colors: ['#10b981', '#06b6d4', '#9887fa']
      // , '#9887fa', '#9C27B0'],
    }
  };

  //graph options end

  // console.log(footfalldata);
  return (
    <>
      {/* {custCount.length > 0 ? (
        <Box sx={{ overflow: 'hidden' }}>
          <ReactApexChart options={state.options} series={state.series} type="area" width="100%" height={400} />
        </Box>
      ) : (
        
      )} */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) : osa.length > 0 ? (
        <Box sx={{ overflow: 'hidden' }}>
          <ReactApexChart options={state.options} series={state.series} type="line" width="100%" height={400} />
        </Box>
      ) : (
        <div className="w-full h-full flex justify-center place-items-center text-xl">No data</div>
      )}
    </>
  );
};

export default Areachart;
