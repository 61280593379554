import React from 'react';

// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// third-party
import { useSelector } from 'react-redux';

// project import
import theme from 'themes';
import Routes from 'routes/index';
import NavigationScroll from './NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  // const [stepsEnabled, setStepsEnabled] = useState(true);
  // const steps = [
  //   {
  //     element: '.filter',
  //     intro: 'Step 1'
  //   },
  //   {
  //     element: '.calendar',
  //     intro: 'Step 2'
  //   },
  //   {
  //     element: '.profile',
  //     intro: 'Step 3'
  //   },
  //   {
  //     element: '#step4',
  //     intro: 'Step 4'
  //   },
  //   {
  //     element: '#step5',
  //     intro: 'Step 5'
  //   },
  //   {
  //     element: '#step6',
  //     intro: 'Step 6'
  //   },
  //   {
  //     element: '#step7',
  //     intro: 'Step 7'
  //   },
  //   {
  //     element: '#step8',
  //     intro: 'Step 8'
  //   },
  //   {
  //     element: '#step9',
  //     intro: 'Step 9'
  //   },
  //   {
  //     element: '#step10',
  //     intro: 'Step 10'
  //   }
  // ];
  return (
    <>
      {
        <NavigationScroll>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </StyledEngineProvider>
        </NavigationScroll>
      }
    </>
  );
};

export default App;
