/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { GetBayWiseOSA } from 'api';

const RadarChart = ({ date }) => {
  // { storeId, date }
  const [radarData, setRadarData] = useState([]);
  const [options, setOptions] = useState({});
  const defaultOption = {
    chart: {
      type: 'radar',
      toolbar: {
        show: false
      }
    },
    series: [
      {
        name: 'No data available',
        data: [0, 0, 0, 0, 0, 0, 0, 0]
      }
    ],
    labels: ['bay 1', 'bay 2', 'bay 3', 'bay 4', 'bay 5', 'bay 6', 'bay 7', 'bay 8']
  };
  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;
  useEffect(() => {
    const fetchData = async () => {
      const body = {
        dates: date.toString(),
        store_id: analysisStoreId
      };
      const response = await GetBayWiseOSA(body);
      setRadarData(response.data);
      console.log('RadarData', response.data);
      const labels = response.data.length !== 0 ? response.data.map((item) => item.bayName) : [];
      const seriesData = response.data.length !== 0 ? response.data.map((item) => parseFloat(item.fullness)) : [];
      setOptions({
        chart: {
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        series: [
          {
            name: 'OSA score',
            data: seriesData
          }
        ],
        labels: labels
      });
    };
    fetchData();
    // eslint-disable-next-line
  }, [date]);

  return (
    <div className="h-full p-2 ">
      <div className="flex flex-col items-center">
        <p className="font-semibold text-xl  ">Store Goodness Profile</p>
        {radarData.length !== 0 ? (
          <Chart options={options} series={options.series} type="radar" height={240} />
        ) : (
          <div className="flex-grow relative">
            <Chart className="relative" options={defaultOption} series={defaultOption.series} type="radar" height={240} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RadarChart;
