// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA_hJX9Xt16PezjATYdXwlEZIe2vYyR9ps',
  authDomain: 'bluslace-prod-auth.firebaseapp.com',
  projectId: 'bluslace-prod-auth',
  storageBucket: 'bluslace-prod-auth.appspot.com',
  messagingSenderId: '988540608335',
  appId: '1:988540608335:web:36b0464de8815c06f8af3a'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const getFirebaseAuth = () => getAuth(getApp());
export const auth = getAuth(app);
