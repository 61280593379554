import React from 'react'; // { useState }
import Chart from 'react-apexcharts';

const Intensity = ({ dta, ind, mob, calculate }) => {
  // Sample data for the line chart
  const data = dta.map((item) => Math.round(item.intensity));
  const index = dta.map((item, id) => id + 1);
  const max = Math.max(...data);
  let updatedMax = max;
  console.log('Graph Data', data);

  if (max > 100) {
    updatedMax = Math.round(max + 25 - (max % 25));
  }

  const markerClickHandler = (selectedXAxisData) => {
    const selectedIndexData = dta[selectedXAxisData - 1];
    const { xmin, xmax, ymin, ymax } = selectedIndexData;
    console.log(`xmin: ${xmin}, xmax: ${xmax}, ymin: ${ymin}, ymax: ${ymax}`);
    calculate(xmin, ymin, xmax, ymax);
    setTimeout(() => {
      calculate(0, 0, 0, 0);
    }, 2500);
  };

  // const [
  //   series
  //   // ,setSeries
  // ] = useState([
  //   {
  //     name: 'Intensity',
  //     data: data
  //   }
  // ]);

  const series = [
    {
      name: 'Intensity',
      data: data
    }
  ];
  const options = {
    chart: {
      id: 'basic-line',
      toolbar: {
        show: false
      },
      colors: mob ? '#000' : '#ffffff',
      events: {
        markerClick: function (event, chartContext, config) {
          const {
            // seriesIndex,
            dataPointIndex
          } = config;
          // const selectedData = series[seriesIndex].data[dataPointIndex];
          const selectedXAxisData = index[dataPointIndex];
          // console.log(`Intensity : ${selectedData}`);
          console.log(`X Axis Data: ${selectedXAxisData}`);
          markerClickHandler(selectedXAxisData);
        }
      }
    },
    title: {
      text: mob ? `Intensity Grad. Vis. Col - ${ind + 1}` : `Intensity Gradient Visualization ( col - ${ind + 1}  ) `,
      style: {
        color: mob ? '#000' : '#ffffff'
      },
      align: 'center'
    },
    stroke: {
      width: mob ? 3 : 5,
      colors: '#06B6D4'
    },
    markers: {
      size: mob ? 3 : 5,
      colors: '#06B6D4'
    },
    xaxis: {
      categories: index,
      labels: {
        style: {
          colors: mob ? '#000' : '#ffffff'
        }
      },
      title: {
        text: 'Image Index (Top to Bottom)', // Y-axis title
        style: {
          color: mob ? '#000' : '#ffffff' // Set color of y-axis title
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: mob ? '#000' : '#ffffff'
        }
      },
      title: {
        text: 'Intensity', // Y-axis title
        style: {
          color: mob ? '#000' : '#ffffff' // Set color of y-axis title
        }
      },
      tickAmount: max < 100 ? 4 : updatedMax / 25,
      max: max < 100 ? 100 : updatedMax,
      min: 0,
      tickPlacement: 'between',
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: mob ? '#000' : '#ffffff'
      },
      grid: {
        show: true,
        borderColor: 'white',
        strokeDashArray: 3
      }
    },
    grid: {
      show: true,
      strokeDashArray: 3
    },
    tooltip: {
      theme: 'dark', // Set tooltip theme
      x: {
        formatter: function (value) {
          return `Category: ${dta[value - 1]?.category}`; // Format x-axis value
        }
      },
      y: {
        formatter: function (value) {
          return ` ${value}`; // Format y-axis value
        }
      }
    }
  };

  console.log('updated max', updatedMax);

  return (
    <>
      <div className="rounded-2xl h-82 w-full flex justify-center item-center mb-10 border-red-500">
        <Chart options={options} series={series} type="line" width={mob ? 250 : 350} height={250} />
      </div>
    </>
  );
};

export default Intensity;
