/* eslint-disable */
// import React, { useState } from 'react';
// import Grid from '@mui/material/Grid';
// import { Paper } from '@mui/material';
// import { FaCamera } from 'react-icons/fa';
// import src1 from '../../../assets/images/heatmap.jpg';
// import src2 from '../../../assets/images/heatmap2.jpg';

// const imgURLs = {
//   camera1: src1,
//   camera2: src2
// };

// export default function ShelfView() {
//   const [url, setUrl] = useState(imgURLs.camera1);
//   const [active, setActive] = useState('camera1');
//   return (
//     <div style={{ height: '400px', margin: '20px' }}>
//       <Grid container spacing={4}>
//         <Grid item md={3}>
//           <Paper
//             elevation={4}
//             className="flex items-center mb-4 cursor-pointer p-5  "
//             onClick={() => {
//               setUrl(imgURLs.camera1);
//               setActive('camera1');
//             }}
//             style={{
//               backgroundColor: active === 'camera1' ? 'black' : 'white',
//               color: active === 'camera1' ? 'white' : 'black',
//               fontWeight: 'bolder'
//             }}
//           >
//             {/* <div  className='bg-gray-200 m-2  rounded' style={{height:"100px", width:"100px"}}></div> */}
//             <div
//               style={{
//                 height: '30px',
//                 width: '30px',
//                 borderRadius: '50%',
//                 background: 'black',
//                 color: 'white',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 marginRight: '8px'
//               }}
//             >
//               {' '}
//               <FaCamera />{' '}
//             </div>
//             <h6>Camera 1</h6>
//           </Paper>
//           <Paper
//             elevation={4}
//             className="flex items-center mb-4 cursor-pointer p-5 "
//             onClick={() => {
//               setUrl(imgURLs.camera2);
//               setActive('camera2');
//             }}
//             style={{
//               backgroundColor: active === 'camera2' ? 'black' : 'white',
//               color: active === 'camera2' ? 'white' : 'black',
//               fontWeight: 'bolder'
//             }}
//           >
//             {/* <div  className='bg-gray-200 m-2  rounded' style={{height:"100px", width:"100px"}}></div> */}
//             <div
//               style={{
//                 height: '30px',
//                 width: '30px',
//                 borderRadius: '50%',
//                 background: 'black',
//                 color: 'white',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 marginRight: '8px'
//               }}
//             >
//               {' '}
//               <FaCamera />{' '}
//             </div>
//             <h6>Camera 2</h6>
//           </Paper>
//         </Grid>

//         <Grid item md={9}>
//           {!url ? <div>please select one camera</div> : <img src={url} alt="img" style={{ height: '400px', width: '100%' }} />}
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { GetBayWiseDetails, GetImagesFromSignedUrl, GetPartsWiseDetails, GetShelfWiseDetails /*GetStoreLayout*/ } from 'api';
import { bouncy } from 'ldrs';
import { useEffect, useRef, useState } from 'react';
// import ShelfView from './shelfView';
import { useParams } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import { ChevronLeftRounded, ChevronRightRounded, MenuRounded } from '@mui/icons-material';
import { ImCross } from 'react-icons/im';
import { RiErrorWarningLine } from 'react-icons/ri';
import Intensity from '../Table/Intensity';
import { IoReturnUpBack } from 'react-icons/io5';
import srcShelf from '.././../../assets/images/no-data-found-shelf-1.avif';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';

bouncy.register();

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#101010bf'
        }
      }
    }
  }
});
export default function ShelfView({ data, baysDetails }) {
  const [loading, setLoading] = useState(true);
  // const [bayToOpen, setBayToOpen] = useState({});

  const imageRef = useRef(null);
  const [openShelves, setOpenShelves] = useState(false);
  const [openBay, setOpenBay] = useState(false);
  const [currentBay, setCurrentBay] = useState(data); // [0,1,2,3,4,5,6,7,8
  const [currentShelf, setCurrentShelf] = useState({}); // [0,1,2,3,4,5,6,7,8
  const [scaleFactor, setScaleFactor] = useState(1);
  //const isMdOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isSmOrLarger = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isLgOrLarger = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [layoutData, setLayoutData] = useState({});

  const [updatedPartDetails, setUpdatedPartDetails] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [liveImg, setLiveImg] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anomalyclr, setAnomalyclr] = useState(false);

  const imageRefGraph = useRef(null);
  const [antn, setAntn] = useState(false);
  const [pos, setPos] = useState([]);
  const [posAll, setposAll] = useState([]);
  const [natural, setNaturel] = useState({ wdth: false, hght: false });
  const [bdetails, setBdetails] = useState(false);

  const open = Boolean(anchorEl);

  const { storeId } = useParams();
  const date = useSelector((state) => state.customization.selectedDate)
    .toISOString()
    .slice(0, 10);
  //const date = new Date();
  //const today = date.toISOString().split('T')[0];
  //const [selectedDate /*setSelectedDate*/] = useState(new Date().toISOString().slice(0, 10)); // Sets initial value to today's date

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseImageDialog = () => {
    setPos([]);
    // setposAll([]);
    setAntn(false);
    setIsImageDialogOpen(false);
  };

  // const handleImageClick = (imageUrl) => {
  //   setSelectedImage(imageUrl);
  //   setIsImageDialogOpen(true);
  // };
  const handleImageClick = (item) => {
    if (item?.img_url) {
      setSelectedImage(item);
      setIsImageDialogOpen(true);
    }
    if (item?.img_url) {
      setSelectedImage(item);
      setIsImageDialogOpen(true);
    }
  };

  const getLayoutData = async () => {
    // const input = {
    //   Store_IDs: ['6582be9ac5ed94d792a563b8'],
    //   // start_date: '2024-01-01'
    //   start_date: today
    // };
    // const response = await GetStoreLayout(input);
    const response = await GetBayWiseDetails(date, storeId);

    console.log('layoutData', response.data[0]);
    setLayoutData(response.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    getLayoutData();
  }, [date]);

  const findMidpoint = (coordinates, dimensions) => {
    const { x, y } = coordinates;
    const { width, height } = dimensions;
    const midPoint = { x: x + width / 2, y: y + height / 2 };
    return midPoint;
  };

  const findDimensions = (event) => {
    setLoading(false);
    const { naturalWidth } = event.target;
    const imgDiv = imageRef.current;
    const { width } = imgDiv.getBoundingClientRect();
    setScaleFactor(width / naturalWidth);
  };

  const getShelfData = async (bay_id) => {
    const response = await GetShelfWiseDetails(date, bay_id);
    return response.data;
  };

  const getPartsData = async (shelf_id) => {
    console.log('shelf_id', shelf_id);
    const response = await GetPartsWiseDetails(date, shelf_id);
    return response.data;
  };

  const handleCloseBay = () => {
    setOpenBay(false);
    setLoading(true);
    // getLayoutData();
  };
  const handleOpenBay = async (item) => {
    setLoading(true);
    setOpenShelves(false);
    setIsImageDialogOpen(false);
    try {
      const shelfData = await getShelfData(item.bayID);
      console.log(shelfData);
      setCurrentBay(shelfData[0]);
    } catch (error) {
      console.log('Problem in getting the Shelf Data', error);
    } finally {
      setLoading(false);
    }
    setOpenBay(true);
  };

  const handlePrevBay = async () => {
    let sortedBayArray = layoutData.bayDetails.sort((a, b) => {
      const numA = parseInt(a.bay_name.split(' ')[1]);
      const numB = parseInt(b.bay_name.split(' ')[1]);
      return numA - numB;
    });

    const currentBayName = currentBay?.bay_name;
    const currentIndex = sortedBayArray.findIndex((bay) => bay.bay_name === currentBayName);
    const newIndex = currentIndex === 0 ? layoutData.bayDetails.length - 1 : currentIndex - 1;

    try {
      const shelfData = await getShelfData(sortedBayArray[newIndex].bayID);
      setCurrentBay(shelfData[0]);
    } catch (error) {
      console.log('Problem in the previous phase', error);
    }
  };

  const handleNextBay = async () => {
    let sortedBayArray = layoutData.bayDetails.sort((a, b) => {
      const numA = parseInt(a.bay_name.split(' ')[1]);
      const numB = parseInt(b.bay_name.split(' ')[1]);
      return numA - numB;
    });

    const currentBayName = currentBay?.bay_name;
    const currentIndex = sortedBayArray.findIndex((bay) => bay.bay_name === currentBayName);
    const newIndex = (currentIndex + 1) % layoutData.bayDetails.length;

    try {
      const shelfData = await getShelfData(sortedBayArray[newIndex].bayID);
      setCurrentBay(shelfData[0]);
    } catch (error) {
      console.log('Problem in the next phase', error);
    }
  };
  const handleCloseShelves = () => {
    setOpenShelves(false);
    getLayoutData();
  };
  const handleGetUpdatedPartDetails = async (item) => {
    setLoading(true);
    const input = item.partsDetails.filter((value) => value.img_url);
    console.log('input is', input);
    let data;
    if (input.length > 0) {
      setImgLoading(true);
      data = await GetImagesFromSignedUrl(input);
      // console.log('api data', data);
    }
    const mergedPartsDetails = item.partsDetails.map((originalPart) => {
      const matchingApiData = data?.data?.find((apiPart) => apiPart.name === originalPart.name);
      return matchingApiData || originalPart;
    });
    setposAll([]);
    setUpdatedPartDetails(mergedPartsDetails);
    setLoading(false);
  };
  const handleOpenShelves = async (item) => {
    // console.log('partdetails', item.partsDetails);
    // while (imgLoading) {
    //   // setLoading(true);
    // }
    try {
      const partsData = await getPartsData(item.shelf_id);
      console.log('partsData', partsData);
      setCurrentShelf(partsData[0]);
      handleGetUpdatedPartDetails(partsData[0]);
    } catch (error) {
      console.log('why why why', error);
    } finally {
      setLoading(false);
    }
    handleCloseBay();
    setOpenShelves(true);
  };
  // console.log('updated parts', updatedPartDetails);
  const handleBack = () => {
    if (openBay) {
      console.log('back button open bay');
      handleCloseBay();
      getLayoutData();
    } else if (openShelves) {
      console.log('back button open shelves');
      handleCloseShelves();
      handleOpenBay(currentBay);
    } else navigate('/main/stores');
    // console.log('back button');
  };
  const handleKeyDownBay = (e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevBay();
    } else if (e.key === 'ArrowRight') {
      handleNextBay();
    }
  };
  const handleKeyDownShelves = (e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevShelves();
    } else if (e.key === 'ArrowRight') {
      handleNextShelves();
    }
  };
  const handlePrevShelves = async () => {
    console.log('current shelf', currentShelf);
    // SORTING THE SHELVES IN THE BASIS OF THEIR NAME
    let sortedShelvesArray = currentBay.shelves.sort((a, b) => {
      return a.shelf_name.localeCompare(b.shelf_name);
    });

    // CHECKING FOR BOTTOM SHELF
    const hasBottomShelf = currentBay?.shelves?.some((shelf) => shelf.location === 'bottom');
    // CHECKING FOR TOP SHELF
    const hasTopShelf = currentBay?.shelves?.some((shelf) => shelf.location === 'top');
    var nInd = 1;

    // IF TOP SHELF IS NOT PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    if (!hasTopShelf) {
      console.log('This is sorted shleves array hrere', sortedShelvesArray[0]);

      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === 0) {
        nInd = currentBay?.shelves?.length - 1;
      } else if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === 3) {
        nInd = 1;
      } else {
        nInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]) - 1;
      }
      try {
        const partData = await getPartsData(sortedShelvesArray[nInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log(error);
      }
    }
    // IF BOTTOM SHELF IS PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    else if (hasBottomShelf) {
      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === 0) {
        nInd = currentBay?.shelves?.length - 1;
      } else {
        nInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]) - 1;
      }

      try {
        const partData = await getPartsData(sortedShelvesArray[nInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log(error);
      }
    }

    // IF BOTTOM SHELF IS NOT PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    else {
      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === 1) {
        nInd = currentBay?.shelves?.length - 1;
      } else {
        nInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]) - 2;
      }
      try {
        const partData = await getPartsData(sortedShelvesArray[nInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log('Idk why some error', error);
      }
    }
  };

  const handleNextShelves = async () => {
    console.log('current shelf', currentShelf);
    // SORTING THE SHELVES IN THE BASIS OF THEIR NAME
    let sortedShelvesArray = currentBay.shelves.sort((a, b) => {
      return a.shelf_name.localeCompare(b.shelf_name);
    });

    // CHECKING FOR BOTTOM SHELF
    const hasBottomShelf = currentBay?.shelves?.some((shelf) => shelf.location === 'bottom');
    const hasTopShelf = currentBay?.shelves?.some((shelf) => shelf.location === 'top');
    var pInd = 1;
    //IF TOP SHELF IS NOT PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    if (!hasTopShelf) {
      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === currentBay.shelves.length) {
        pInd = 0;
      } else {
        pInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]) + 1;
      }
      try {
        const partData = await getPartsData(sortedShelvesArray[pInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log(error);
      }
    }
    // IF BOTTOM SHELF IS PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    else if (hasBottomShelf) {
      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === currentBay.shelves.length - 1) {
        pInd = 0;
      } else {
        pInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]) + 1;
      }

      try {
        const partData = await getPartsData(sortedShelvesArray[pInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log(error);
      }
    }

    // IF BOTTOM SHELF IS NOT PRESENT THEN CONDITION FOR SELECTING CURRENTSHELF
    else {
      if (parseInt(currentShelf?.shelf_name?.split(' ')[2]) === currentBay.shelves.length) {
        pInd = 0;
      } else {
        pInd = parseInt(currentShelf?.shelf_name?.split(' ')[2]);
      }
      try {
        const partData = await getPartsData(sortedShelvesArray[pInd].shelf_id);
        setCurrentShelf(partData[0]);
        handleGetUpdatedPartDetails(partData[0]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handlePrevPart = () => {
    setPos([]);
    setposAll([]);
    setAntn(false);
    let sortedPartsArray = updatedPartDetails.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    parseInt(selectedImage?.name?.replace(/ +/g, ' ').split(' ')[1]) === 1
      ? setSelectedImage(sortedPartsArray[updatedPartDetails.length - 1])
      : setSelectedImage(sortedPartsArray[parseInt(selectedImage?.name?.replace(/ +/g, ' ').split(' ')[1]) - 2]);
  };
  const handleNextPart = () => {
    setPos([]);
    setposAll([]);
    setAntn(false);
    let sortedPartsArray = updatedPartDetails.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    parseInt(selectedImage?.name?.replace(/ +/g, ' ').split(' ')[1]) === updatedPartDetails.length
      ? setSelectedImage(sortedPartsArray[0])
      : setSelectedImage(sortedPartsArray[parseInt(selectedImage?.name?.replace(/ +/g, ' ').split(' ')[1])]);
  };

  const handleKeyDownPart = (e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevPart();
    } else if (e.key === 'ArrowRight') {
      handleNextPart();
    }
  };
  const handleToggleImage = () => {
    setPos([]);
    setposAll([]);
    setAntn(false);
    setImgLoading(true);
    setLiveImg(!liveImg);
  };
  const handleSaveForRetraining = () => {
    toast.error('This feature is not available yet');
  };
  const findDimensionsGraph = (event, data, index) => {
    setImgLoading(false);

    const { naturalWidth, naturalHeight } = event.target;
    // const imgDiv = imageRef.current;
    // const { width, height } = imgDiv.getBoundingClientRect();
    getPos(data, index, naturalWidth, naturalHeight);
    setNaturel({ wdth: naturalWidth, hght: naturalHeight });

    // setScaleFactor(width / naturalWidth);
  };
  // const findDimensionsGraph2 = () => {
  //   updatedPartDetails?.map((item,index)=>{
  //     item?.anomalies_detals?.anomalies_found[0]?.details, index
  //     getPos(item?.anomalies_detals?.anomalies_found[0]?.details, index, natural.wdth, natural.hght);
  //   })

  //   // console.log('aakh dikhata hai chadarmod', data, index, natural.wdth, natural.hght);
  // };
  const handleBay10Click = async (mallName) => {
    console.log('clicked', mallName);
    const phoneNumber = localStorage.getItem('phoneNumber');
    console.log('phone', phoneNumber);
    const body = {
      stores: ['65c74d4112465588b7a4984c'],
      date: date,
      number: phoneNumber
    };
    let data;
    try {
      const response = await GetNeodishaVerifiedUser(body);
      console.log('response', response.data);
      data = response.data;
    } catch (error) {
      console.error('Error fetching data', error);
    }
    if (data.message === 'user  found') {
      // Retrieve access token from localStorage
      const accessToken = 'access Token';

      // If access token is available, add it to data object
      // if (accessToken) {
      data.accessToken = accessToken;
      let newData = { ...data, mallName: mallName };
      console.log('newData', newData);
      const encodedObj = encodeURIComponent(JSON.stringify(newData));
      console.log(encodedObj);
      window.open(`https://neodisha.neophyte.live/?redirect=analysis&data=${encodedObj}`, '_blank', 'noreferrer');
      // } else {
      //   // Handle scenario where access token is not available
      //   toast.error('Access token not found');
      // }
    } else {
      toast.error('You are not authorized to access');
    }
  };
  function calculate(xmin, ymin, xmax, ymax) {
    const lft = (xmin / natural.wdth) * 100;
    const top = (ymin / natural.hght) * 100;
    const width = ((xmax - xmin) / natural.wdth) * 100;
    const height = ((ymax - ymin) / natural.hght) * 100;
    setPos({ lft: lft, tp: top, wdth: width, hght: height });
    setAntn(true);

    // setTimeout(() => {
    //   // Code to be executed after 2 seconds
    //   setPos({ lft: false, tp: false, wdth: false, hght: false });
    //   setAntn(false);
    // }, 2000);
  }

  const highlightStyle = {
    position: 'absolute',
    left: `${pos.lft}%`,
    top: `${pos.tp}%`,
    width: `${pos.wdth}%`,
    height: `${pos.hght}%`,
    border: '1px solid red', // Change border color as desired
    boxSizing: 'border-box',
    pointerEvents: 'none', // So clicks can still interact with the image
    backgroundColor: anomalyclr ? 'rgba(255, 0, 0, 0.6)' : 'rgb(0, 255, 0, 0.4)',
    borderRadius: '5px'
  };

  useEffect(() => {
    // console.log('Received data:', data);
    // setOpenBay(false);
    if (layoutData.bayDetails != undefined) {
      setBdetails(layoutData.bayDetails);
      setLoading(true);
      handleOpenBay(layoutData.bayDetails[0]);
    }
    // Use the data to fetch additional details or render the view accordingly
  }, [layoutData]);

  const handleClickOpenBay = async () => {
    //setCurrentBay(data)
    // if(data != null){
    await handleOpenBay(data);
    // }
  };
  // useEffect(() => {

  // }, [baysDetails]);
  useEffect(() => {
    console.log('Received data:', currentBay);
    // Use the data to fetch additional details or render the view accordingly
    !openShelves && handleClickOpenBay();
  }, [openBay]);
  console.log('bay details is', bdetails, openBay, openShelves);

  const calculate2 = (xmin, ymin, xmax, ymax, index, naturalWidth, naturalHeight) => {
    console.log('mindatas', xmin, ymin, xmax, ymax, naturalWidth, naturalHeight);
    const lft = (xmin / naturalWidth) * 100;
    const top = (ymin / naturalHeight) * 100;
    const width = ((xmax - xmin) / naturalWidth) * 100;
    const height = ((ymax - ymin) / naturalHeight) * 100;
    setAntn(true);
    return { lft: lft, tp: top, wdth: width, hght: height, index: index };
  };

  const getPos = (anmdata, index, naturalWidth, naturalHeight) => {
    console.log('Over view updated part details anmdata  ', anmdata);
    if (anmdata) {
      const positions = anmdata.map((item) => calculate2(item.xmin, item.ymin, item.xmax, item.ymax, index, naturalWidth, naturalHeight));

      // positions.push({index:index});
      console.log('Over view updated part details position', positions);
      // setPos(positions);
      // setPos((prev) => [...prev, positions]);
      setposAll((prev) => [...prev, positions]);
      console.log('Over view updated part details by id:', updatedPartDetails?.length, index + 1);

      // return positions;
    }
    // if (updatedPartDetails?.length === index + 1) {
    //   const result = posAll.flat().reduce((acc, obj) => {
    //     const { index } = obj;
    //     if (!acc[index]) {
    //       acc[index] = [];
    //     }
    //     acc[index].push(obj);
    //     return acc;
    //   }, {});
    //   // console.log("Over view updated part details by id:", result )
    // }
    return false;
  };
  const [updatedPos, setUpdatedPos] = useState([]);
  useEffect(() => {
    const result =
      posAll &&
      Array.isArray(posAll) &&
      posAll.flat().reduce((acc, obj) => {
        const { index } = obj;
        if (!acc[index]) {
          acc[index] = [];
        }
        acc[index].push(obj);
        return acc;
      }, {});
    console.log('Over view updated part details by id:', result);
    setUpdatedPos(result);
  }, [posAll]);

  console.log('Over view updated part details', updatedPartDetails);
  console.log('Over view updated part details selected img', selectedImage);
  console.log('Over view updated part details position', posAll);
  const [showAnomaly, setShowAnomaly] = useState(false);
  function handleShowAnomaly(event) {
    // setposAll([]);
    console.log('toggle button clicked', event.target.checked);
    if (event.target.checked) {
      // findDimensionsGraph2();
    }
    setShowAnomaly(event.target.checked);
  }
  return (
    <>
      <>
        {openShelves && (
          <button
            onClick={handleClickOpenBay}
            /*onClick={()=> {
              setCurrentBay(currentBay)
              handleOpenBay(currentBay)
            }}*/
            style={{
              backgroundColor: 'black',
              color: '#fff',
              padding: '4px',
              borderRadius: '17px 0 0 17px',
              marginRight: '3px',
              width: '15%',
              fontSize: '1rem'
            }}
          >
            Bay List
          </button>
        )}
        {loading ? (
          <div
            /*onClick={()=> {
              setCurrentBay(bay)
              handleOpenBay(bay)
            }}*/
            style={{
              alignContent: 'center',
              display: 'flex',
              flexGrow: '1',
              padding: '6%',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
          </div>
        ) : (
          <Grid container sx={{ height: '100%' }}>
            {openBay && (
              <Grid
                item
                md={2}
                lg={2}
                xl={2}
                /*onClick={() => {
                                    console.log('Hello')
                                    setCurrentBay(data)
                                    //handleClickOpenBay
                                  }}*/
                sx={{ width: '100%', height: '100%', overflow: 'auto', padding: '5px' }}
                className="scrollbar"
              >
                {bdetails &&
                  bdetails?.map((bay) => (
                    <Paper
                      onClick={() => {
                        //console.log('Hello')
                        setCurrentBay(bay);
                        //handleClickOpenBay(currentBay)
                        handleOpenBay(bay);
                      }}
                      elevation={4}
                      className="flex justify-center items-center mb-4 cursor-pointer p-4 "
                      style={{
                        backgroundColor: currentBay?.bay_name === bay.bay_name ? 'black' : 'white',
                        color: currentBay?.bay_name === bay.bay_name ? 'white' : 'black',
                        fontWeight: 'bolder',
                        fontSize: currentBay?.bay_name === bay.bay_name ? '1.2rem' : '1.2rem'
                      }}
                    >
                      <h6>{bay.bay_name}</h6>
                    </Paper>
                  ))}
              </Grid>
            )}
            <Grid
              item
              md={10}
              lg={10}
              xl={10}
              sx={{ paddingTop: '1%', padding: '2%', height: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto' }}
              className="scrollbar"
            >
              {openBay && (
                <Stack direction={'column'} justifyContent={'space-between'}>
                  {/* <div className="flex items-center gap-2 w-24 cursor-pointer  border-red-500" onClick={handleBack}>
                    <IoReturnUpBack className="text-lg cursor-pointer text-gray-600 opacity-60 hover:opacity-100" style={{}} />
                    <span className="cursor-pointer text-lg text-black-600 opacity-60 hover:opacity-100">Back</span>
                  </div> */}

                  <div className={`w-full relative flex justify-center items-center ${loading ? 'h-0' : ''}`}>
                    <ChevronLeftRounded
                      onClick={handlePrevBay}
                      className="text-gray-400 opacity-50 hover:opacity-100 absolute z-10 cursor-pointer lg:left-[2%] lg:top-[45%] left-0 top-[35%] w-10 h-10 md:w-20 md:h-20"
                      onKeyDown={handleKeyDownBay}
                      tabIndex="0"
                    />
                    <ChevronRightRounded
                      onClick={handleNextBay}
                      className="text-gray-400 opacity-50 hover:opacity-100 w-10 h-10 md:w-20 md:h-20 absolute z-10 cursor-pointer lg:right-[2%] lg:top-[45%] right-0 top-[35%]"
                      onKeyDown={handleKeyDownBay}
                      tabIndex="0"
                    />

                    <div
                      className={`lg:w-[45%] w-[70%]  lg:h-[67vh] h-[45vh]`}
                      style={{
                        perspective: '900px'
                      }}
                    >
                      <div
                        className={`w-full h-full  grid grid-rows-[repeat(9,_minmax(0,_1fr))] relative`}
                        style={{
                          transform: 'rotateX(45deg)',
                          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))'
                        }}
                      >
                        {currentBay?.shelves?.map((item, index) => {
                          // console.log('clicked', item);
                          if (item.location === 'left')
                            return (
                              <div
                                key={index}
                                className={`${
                                  item?.hasAnomaly
                                    ? 'border-red-600 hover:bg-red-200'
                                    : item?.hasAnomaly != undefined
                                    ? 'border-emerald-500 hover:bg-emerald-200'
                                    : 'border-gray-500 hover:bg-gray-200'
                                  // item?.shelf_fullness >= 80
                                  //   ? 'border-emerald-500 hover:bg-emerald-200'
                                  //   : item?.shelf_fullness >= 50 && item?.shelf_fullness < 80
                                  //   ? 'border-orange-500 hover:bg-orange-200'
                                  //   : item?.shelf_fullness < 50
                                  //   ? 'border-red-600 hover:bg-red-200'
                                  //   : 'border-gray-500 hover:bg-gray-200'
                                } border-[5px] rounded-lg col-span-1 cursor-pointer row-start-2 flex justify-center items-center lg:text-xl text-base font-semibold  duration-500`}
                                style={{
                                  gridRowEnd: 8
                                }}
                                onClick={() => {
                                  console.log('left', item.shelf_name);
                                  console.log('left', item.location);
                                  // if (item?.shelf_fullness != 0) {
                                  setLoading(true);
                                  handleOpenShelves(item);
                                  // }
                                }}
                              >
                                <div className="h-full flex justify-center items-center">
                                  <div className="-rotate-90 border-0 border-red-500 m-0 w-48 text-center flex-col flex gap-1 justify-center">
                                    <p>{item.shelf_name}</p>

                                    <div className=" border-0 border-red-500 m-0 w-48 text-center flex gap-4 justify-center">
                                      {/* <br /> */}

                                      {isSmOrLarger && (
                                        <p className="text-base ">
                                          {' '}
                                          {isNaN(item?.shelf_fullness) ? 'No Capture' : 'OSA  ' + Math.floor(item?.shelf_fullness) + '%'}
                                        </p>
                                      )}
                                      {isSmOrLarger && (
                                        <p className="text-base ">{!isNaN(item?.vm_score) && `VM ${Math.floor(item?.vm_score)} %`}</p>
                                      )}
                                    </div>
                                  </div>
                                  {/* <p className="-rotate-90 border-0 border-red-500 m-0 w-24 text-center">{item.shelf_fullness}</p> */}
                                </div>
                              </div>
                            );
                          else if (item.location === 'right')
                            return (
                              <div
                                key={index}
                                className={`${
                                  item?.hasAnomaly
                                    ? 'border-red-600 hover:bg-red-200'
                                    : item?.hasAnomaly != undefined
                                    ? 'border-emerald-500 hover:bg-emerald-200'
                                    : 'border-gray-500 hover:bg-gray-200'
                                  // item?.shelf_fullness >= 80
                                  //   ? 'border-emerald-500 hover:bg-emerald-200'
                                  //   : item?.shelf_fullness >= 50 && item?.shelf_fullness < 80
                                  //   ? 'border-orange-500 hover:bg-orange-200'
                                  //   : item?.shelf_fullness < 50
                                  //   ? 'border-red-600 hover:bg-red-200'
                                  //   : 'border-gray-500 hover:bg-gray-200'
                                }
                                  border-emerald-500 border-[5px] rounded-lg col-span-1 row-start-2 flex justify-center items-center lg:text-xl text-sm font-semibold hover:bg-emerald-200 duration-500`}
                                style={{
                                  gridRowEnd: 8,
                                  gridColumnStart: 6
                                }}
                                onClick={() => {
                                  console.log('right', item.shelf_name);
                                  handleOpenShelves(item);
                                }}
                              >
                                <div className="h-full flex justify-center items-center cursor-pointer">
                                  {/* <p className="rotate-90 border-0 border-red-500 m-0 w-28 text-center">
                                      shelf - 3
                                      <br />
                                      {isNaN(item?.shelf_fullness) ? 'No Capture' : Math.floor(item?.shelf_fullness) + '%'}
                                      </p> */}
                                  <div className="rotate-90 border-0 border-red-500 m-0 w-48 text-center flex flex-col gap-1 justify-center">
                                    <p>{item.shelf_name}</p>

                                    {/* <br /> */}
                                    <div className=" border-0 border-red-500 m-0 w-48 text-center flex gap-4 justify-center">
                                      {isSmOrLarger && (
                                        <p className="text-base ">
                                          {isNaN(item?.shelf_fullness) ? 'No Capture' : 'OSA ' + Math.floor(item?.shelf_fullness) + '%'}
                                        </p>
                                      )}
                                      {isSmOrLarger && (
                                        <p className="text-base ">{!isNaN(item?.vm_score) && `VM ${Math.floor(item?.vm_score)}%`}</p>
                                      )}
                                    </div>
                                  </div>
                                  {/* <p className="-rotate-90 border-0 border-red-500 m-0 w-24 text-center">{item.shelf_fullness}</p> */}
                                </div>
                              </div>
                            );
                          else if (item.location === 'top') {
                            // console.log("clicked");
                            return (
                              <div
                                key={index}
                                className={`${
                                  item?.hasAnomaly
                                    ? 'border-red-600 hover:bg-red-200'
                                    : item?.hasAnomaly != undefined
                                    ? 'border-emerald-500 hover:bg-emerald-200'
                                    : 'border-gray-500 hover:bg-gray-200'
                                  // item?.shelf_fullness >= 80
                                  //   ? 'border-emerald-500 hover:bg-emerald-200'
                                  //   : item?.shelf_fullness >= 50 && item?.shelf_fullness < 80
                                  //   ? 'border-orange-500 hover:bg-orange-200'
                                  //   : item?.shelf_fullness < 50
                                  //   ? 'border-red-600 hover:bg-red-200'
                                  //   : 'border-gray-500 hover:bg-gray-200'
                                }
                                  border-emerald-500 cursor-pointer border-[5px] rounded-lg col-start-2 row-span-1 flex justify-center items-center lg:text-xl text-sm font-semibold hover:bg-emerald-200 duration-500`}
                                style={{
                                  gridColumnEnd: 6,
                                  gridRowStart: 1
                                }}
                                onClick={() => {
                                  console.log('top', item.shelf_name);
                                  handleOpenShelves(item);
                                }}
                              >
                                {/* <p className="">
                                      shelf - 2
                                      <br />
                                      {isNaN(item?.shelf_fullness) ? 'No Capture' : Math.floor(item?.shelf_fullness) + '%'}
                                  </p> */}
                                <div className="flex justify-center items-center gap-1 ">
                                  <div className=" border-0 border-red-500 m-0 w-48 text-center flex gap-4 justify-center">
                                    <p>{item.shelf_name}</p>

                                    {/* <br /> */}

                                    {isSmOrLarger && (
                                      <p className="text-base ">
                                        {isNaN(item?.shelf_fullness) ? 'No Capture' : 'OSA ' + Math.floor(item?.shelf_fullness) + '%'}
                                      </p>
                                    )}
                                  </div>
                                  {isSmOrLarger && (
                                    <p className="text-base ">{!isNaN(item?.vm_score) && `VM ${Math.floor(item?.vm_score)}%`}</p>
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              key={index}
                              className={`${
                                item?.hasAnomaly
                                  ? 'border-red-600 hover:bg-red-200'
                                  : item?.hasAnomaly != undefined
                                  ? 'border-emerald-500 hover:bg-emerald-200'
                                  : 'border-gray-500 hover:bg-gray-200'
                                // item?.shelf_fullness >= 80
                                //   ? 'border-emerald-500 hover:bg-emerald-200'
                                //   : item?.shelf_fullness >= 50 && item?.shelf_fullness < 80
                                //   ? 'border-orange-500 hover:bg-orange-200'
                                //   : item?.shelf_fullness < 50
                                //   ? 'border-red-600 hover:bg-red-200'
                                //   : 'border-gray-500 hover:bg-gray-200'
                              }
                                  border-emerald-500 cursor-pointer border-[5px] rounded-lg col-start-2 row-span-1 flex justify-center items-center lg:text-xl text-sm font-semibold hover:bg-emerald-200 duration-500`}
                              style={{
                                gridColumnEnd: 6,
                                gridRowStart: currentBay.id === 9 ? 9 : 8
                              }}
                              onClick={() => {
                                // console.log("clicked", item.shelf_name);
                                setPos([]);
                                handleOpenShelves(item);
                              }}
                            >
                              {/* <p className="border-0 border-red-500 ">
                                  shelf - 0
                                  <br />
                                  {isNaN(item?.shelf_fullness) ? 'No Capture' : Math.floor(item?.shelf_fullness) + '%'}
                                  </p> */}
                              <div className="flex justify-center items-center gap-1 ">
                                <div className=" border-0 border-red-500 m-0 w-48 text-center flex gap-4 justify-center">
                                  <p>{item.shelf_name}</p>

                                  {/* <br /> */}

                                  {isSmOrLarger && (
                                    <p className="text-base ">
                                      {isNaN(item?.shelf_fullness) ? 'No Capture' : 'OSA ' + Math.floor(item?.shelf_fullness) + '%'}
                                    </p>
                                  )}
                                </div>
                                {isSmOrLarger && (
                                  <p className="text-base ">{!isNaN(item?.vm_score) && `VM ${Math.floor(item?.vm_score)} %`}</p>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl font-semibold text-gray-600">
                          {currentBay?.bay_name}
                        </div>
                      </div>
                    </div>
                  </div>
                </Stack>
              )}
              {openShelves && !loading && (
                <Stack direction={'column'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                  {/* <div className="flex items-center gap-2 w-24 cursor-pointer  border-red-500" onClick={handleBack}>
                    <IoReturnUpBack className="text-lg cursor-pointer text-gray-600 opacity-60 hover:opacity-100" style={{}} />
                    <span className="cursor-pointer text-lg text-black-600 opacity-60 hover:opacity-100">Back</span>
                  </div> */}
                  {/* <div className={`flex relative justify-end`}> */}
                  <div style={{ position: 'absolute', right: '7px', top: '50px' }}>
                    {/* TOGGLE BUTTON GROUP FOR LIVE AND REFERENCE IMAGE */}
                    <ToggleButtonGroup
                      color="primary"
                      value={liveImg}
                      exclusive
                      onChange={handleToggleImage}
                      aria-label="Platform"
                      className="text-white bg-white"
                    >
                      <ToggleButton
                        value={true}
                        style={{
                          backgroundColor: liveImg ? 'rgb(16, 185, 129)' : '',
                          color: liveImg ? 'white' : '#10b981',
                          borderColor: '#10b981',
                          width: 70
                          // padding: '1px 2px',
                          // fontSize: '0.580rem'
                          // fontSize: '0.8rem'
                        }}
                      >
                        Live
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        style={{
                          backgroundColor: !liveImg ? 'rgb(16, 185, 129)' : '',
                          color: !liveImg ? 'white' : '#10b981',
                          borderColor: '#10b981',
                          width: 100
                          // padding: '1px 2px',
                          // fontSize: '0.580rem'
                          // fontSize: '0.8rem'
                        }}
                      >
                        Reference
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <FormControlLabel
                      className="absolute top-16 right-16"
                      value="Show anomaly"
                      onChange={handleShowAnomaly}
                      control={<Switch color="primary" />}
                      label="Show anomaly"
                      labelPlacement="end"
                    />
                    {/* <div style={{ marginLeft: '10px' }} />
                              <Button className="bg-emerald-500 text-white hover:bg-emerald-600 " onClick={handleSaveForRetraining}>
                              Save for Retraining
                              </Button>
                              <Toaster /> */}
                  </div>
                  <div className={`w-full  flex flex-col justify-center  ${loading ? 'h-0' : ''}`}>
                    <ChevronLeftRounded
                      onClick={handlePrevShelves}
                      className="text-gray-400 opacity-100 hover:opacity-100 text-7xl absolute z-10 cursor-pointer lg:left-[2%] lg:top-[45%] left-0 top-[35%]"
                      onKeyDown={handleKeyDownShelves}
                      tabIndex="0"
                    />
                    <ChevronRightRounded
                      onClick={handleNextShelves}
                      className="text-gray-400 opacity-100 hover:opacity-100 text-7xl absolute z-10 cursor-pointer lg:right-[2%] lg:top-[45%] right-0 top-[35%]"
                      onKeyDown={handleKeyDownShelves}
                      tabIndex="0"
                    />
                    {imgLoading && (
                      <div className="flex justify-center items-center absolute top-0 left-0 z-40  overflow-x-hidden bg-white w-full h-full">
                        <l-bouncy size="45" speed="2" color="black"></l-bouncy>
                      </div>
                    )}
                    <div className="w-full h-full flex lg:justify-center text-3xl font-semibold ml-6  py-6 overflow-auto">
                      {/* MULTIPLE PARTS WITH IMAGE OF WITH OUT IMAGE */}
                      <ImageList
                        sx={{
                          alignItems: 'center'
                        }}
                        cols={currentShelf?.partsDetails?.length / 2}
                        gap={10}
                      >
                        {updatedPartDetails?.map((item, index) => (
                          <ImageListItem
                            key={index}
                            onClick={() => handleImageClick(item)}
                            sx={{
                              gridRow: index % 2 === 0 ? '1' : '2',
                              width: '200px',
                              height: '200px'
                            }}
                          >
                            {item.img_url ? (
                              <div className="relative w-full h-full">
                                {imgLoading && (
                                  <div className="flex justify-center items-center absolute top-0 left-0 z-10  overflow-x-hidden bg-white w-full h-full">
                                    <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
                                  </div>
                                )}
                                <img
                                  src={
                                    liveImg
                                      ? item?.img_url
                                      : item.onboarded_image_url
                                      ? item?.onboarded_image_url
                                      : 'https://img.freepik.com/premium-vector/no-data-found-empty-file-folder-concept-design-vector-illustration_620585-1698.jpg'
                                  }
                                  alt={`Shelf ${index}`}
                                  className="w-full h-full object-cover cursor-pointer "
                                  onLoad={(event) => {
                                    console.log('I am clicked', item?.anomalies_detals?.anomalies_found[0]?.details);
                                    findDimensionsGraph(event, item?.anomalies_detals?.anomalies_found[0]?.details, index);
                                    //  getPos(item?.anomalies_detals?.anomalies_found[0]?.details, index);
                                    setImgLoading(false);
                                  }}
                                />
                                {/* {index === 0 && (
                                  <FormControlLabel
                                    onChange={(event) => {
                                      console.log('hello world', event);
                                    }}
                                    value={showAnomaly}
                                    checked={showAnomaly}
                                    control={<Switch color="primary" />}
                                    label="Show anomaly"
                                    labelPlacement="end"
                                  />
                                )} */}

                                {updatedPos &&
                                  showAnomaly &&
                                  updatedPos[index]?.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: 'absolute',
                                        left: `${item.lft}%`,
                                        top: `${item.tp}%`,
                                        width: `${item.wdth}%`,
                                        height: `${item.hght}%`,
                                        border: '1px solid red', // Change border color as desired
                                        boxSizing: 'border-box',
                                        pointerEvents: 'none', // So clicks can still interact with the image
                                        backgroundColor: 'rgba(255, 0, 0, 0.6)',
                                        borderRadius: '5px'
                                      }}
                                    ></div>
                                  ))}
                              </div>
                            ) : (
                              <img
                                src={
                                  // !item.onboarded_image_url ? 'https://img.freepik.com/premium-vector/no-data-found-empty-file-folder-concept-design-vector-illustration_620585-1698.jpg' : liveImg
                                  liveImg
                                    ? 'https://img.freepik.com/premium-vector/no-data-found-empty-file-folder-concept-design-vector-illustration_620585-1698.jpg'
                                    : item.onboarded_image_url
                                    ? item.onboarded_image_url
                                    : srcShelf
                                }
                                alt="no data found"
                                className=" w-full h-full object-cover"
                                onLoad={() => {
                                  console.log('I am clicked else part', item.onboarded_image_url, liveImg);
                                  setImgLoading(false);
                                }}
                              />
                            )}
                            {/* `Fullness: ${item.avg_full || 0}%` */}
                            <ImageListItemBar
                              title={
                                <Stack direction={'column'} spacing={-1}>
                                  <Typography>{`OSA: ${item.avg_full || 0}%`}</Typography>
                                  {item?.timestamps && <Typography>{`Date: ${item?.timestamps?.split('T')[0]}`}</Typography>}
                                  {item?.timestamps && (
                                    <Typography>{`Time: ${new Date(item.timestamps).toLocaleTimeString([], {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      second: '2-digit'
                                    })}`}</Typography>
                                  )}
                                </Stack>
                              }
                              subtitle={item.name + '/' + updatedPartDetails.length}
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>

                      {/* OPEN A SINGLE IMAGE AND ITS DETAILS */}
                      <Dialog
                        // fullWidth={fullWidth}
                        // maxWidth={maxWidth}
                        fullScreen
                        open={isImageDialogOpen}
                        onClose={handleCloseImageDialog}
                        PaperProps={{
                          sx: {
                            width: '100%',
                            maxHeight: '1300px',
                            background: 'black',
                            boxShadow: 'none'
                          }
                        }}
                      >
                        <DialogContent className="w-full h-full flex justify-center relative overflow-hidden">
                          <div className="self-center ">
                            <ChevronLeftRounded
                              onClick={handlePrevPart}
                              className="text-gray-400 opacity-100 hover:opacity-100 text-7xl absolute z-10 cursor-pointer lg:left-[2%] top-[45%] left-0"
                              onKeyDown={handleKeyDownPart}
                              tabIndex="0"
                            />
                            <ChevronRightRounded
                              onClick={handleNextPart}
                              className="text-gray-400 opacity-100 hover:opacity-100 text-7xl absolute z-10 cursor-pointer lg:right-[2%] top-[45%] right-0"
                              onKeyDown={handleKeyDownPart}
                              tabIndex="0"
                            />
                            <ImCross
                              onClick={handleCloseImageDialog}
                              className="z-20 text-lg cursor-pointer text-white opacity-60 hover:opacity-100 absolute"
                              style={{
                                right: '4%',
                                top: '4%'
                              }}
                            />
                            <div className="xl:flex absolute right-[7%] top-[2%] hidden">
                              {/* TOGGLE BUTTON GROUP FOR LIVE AND REFERENCE IMAGE */}
                              <ToggleButtonGroup
                                color="primary"
                                value={liveImg}
                                exclusive
                                onChange={handleToggleImage}
                                aria-label="Platform"
                                className="text-white bg-white"
                              >
                                <ToggleButton
                                  value={true}
                                  style={{
                                    backgroundColor: liveImg ? 'rgb(16, 185, 129)' : '',
                                    color: liveImg ? 'white' : '#10b981',
                                    borderColor: '#10b981',
                                    width: 100
                                  }}
                                >
                                  Live
                                </ToggleButton>
                                <ToggleButton
                                  value={false}
                                  style={{
                                    backgroundColor: !liveImg ? 'rgb(16, 185, 129)' : '',
                                    color: !liveImg ? 'white' : '#10b981',
                                    borderColor: '#10b981',
                                    width: 100
                                  }}
                                >
                                  Reference
                                </ToggleButton>
                              </ToggleButtonGroup>

                              <div style={{ marginLeft: '10px' }} />
                              <Button className="bg-emerald-500 text-white hover:bg-emerald-600 " onClick={handleSaveForRetraining}>
                                Save for Retraining
                              </Button>
                              <Toaster />
                            </div>

                            <div className="relative w-full h-full">
                              {imgLoading && (
                                <div className="flex justify-center items-center absolute top-0 left-0 z-10  overflow-x-hidden bg-white w-full h-full">
                                  <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
                                </div>
                              )}
                              <img
                                src={liveImg ? selectedImage?.img_url : selectedImage?.onboarded_image_url}
                                alt="NO IMAGE FOUND FOR THIS PART"
                                className="self-center lg:max-h-[95svh] max-h-[80svh] mt-10 md:mt-0 text-white"
                                onLoad={findDimensionsGraph}
                                ref={imageRefGraph}
                              />
                              {antn && <div style={highlightStyle}></div>}
                            </div>

                            {/* STORE, BAY, SHELF */}
                            <div
                              className=" text-xl cursor-pointer text-white absolute hidden xl:block"
                              style={{
                                left: '4%',
                                top: '3%'
                              }}
                            >
                              <Typography variant="h3" className="text-white">
                                {layoutData?.store_id} - {layoutData?.name}
                              </Typography>
                              <Typography variant="h3" className="text-white">
                                {currentBay?.bay_name} / {currentShelf?.shelf_name}
                              </Typography>
                            </div>
                            <div
                              className=" text-xl cursor-pointer text-white absolute xl:block hidden"
                              style={{
                                left: '4%',
                                top: '20%'
                              }}
                            >
                              <Typography variant="h3" className="text-white">
                                {`OSA score: ${Math.floor(selectedImage?.avg_full) || 0}%`}
                              </Typography>
                              {selectedImage?.section != 'bottom' && (
                                <Typography variant="h3" className="text-white mb-3">
                                  {`VM score: ${Math.floor(selectedImage?.vm_score) || 0}%`}
                                </Typography>
                              )}
                              <Typography variant="h3" className="text-white mb-1 mt-4">
                                Anomalies
                              </Typography>
                              <Divider color="white" className="mb-2" />
                              <div className="flex gap-2 flex-wrap  w-96 ">
                                {selectedImage?.anomalies_detals?.anomalies_found.map((item, index) =>
                                  item.details.map((itm, ind) => (
                                    <Tooltip
                                      key={index + ind}
                                      title={
                                        <div>
                                          <Typography variant="body1">Category: {itm.category ? itm.category : 'No Data Found'}</Typography>
                                          <Typography variant="body1">
                                            Intensity: {itm.intensity ? itm.intensity : 'No Data Found'}
                                          </Typography>
                                          <Typography variant="body1">
                                            Color: {itm.color_family ? itm.color_family : 'No Data Found'}
                                          </Typography>
                                        </div>
                                      }
                                    >
                                      <Box
                                        paddingX={0.2}
                                        paddingY={0.04}
                                        className="bg-gray-200 rounded-full flex gap-1 justify-center place-items-center cursor-pointer align-middle text-center hover:bg-amber-500"
                                        onMouseOver={() => {
                                          calculate(itm.xmin, itm.ymin, itm.xmax, itm.ymax);
                                          setAnomalyclr(true);
                                        }}
                                        onMouseOut={() => {
                                          if (antn) {
                                            setPos([]);
                                            setAntn(!antn);
                                          }
                                          setAnomalyclr(false);
                                        }}
                                      >
                                        <RiErrorWarningLine className="text-4xl mr-0.5 text-purple-500" />
                                        <Typography paddingRight={2} variant="h6">
                                          {/* {anomalyType.charAt(0).toUpperCase() + anomalyType.substring(1, anomalyType.indexOf('_'))} */}
                                          {item.type.charAt(0).toUpperCase() + item.type.substring(1, item.type.indexOf('_'))}
                                        </Typography>
                                      </Box>
                                    </Tooltip>
                                  ))
                                )}
                              </div>
                            </div>
                            <div
                              className=" text-xl cursor-pointer text-white absolute xl:block hidden"
                              style={{
                                left: '4%',
                                bottom: '3%'
                              }}
                            >
                              <Typography variant="h3" className="text-white">
                                {`Name: ${selectedImage?.userDetails?.user_name || 'No Data Found'}`}
                              </Typography>
                              <Typography variant="h3" className="text-white">
                                {`Number: ${selectedImage?.userDetails?.number || 'No Data Found'}`}
                              </Typography>
                              <Typography variant="h3" className="text-white">
                                {`Date: ${selectedImage?.timestamps?.split('T')[0] || 'No Data Found'}`}
                              </Typography>
                              <Typography variant="h3" className="text-white">
                                {`Time: ${
                                  isNaN(new Date(selectedImage?.timestamps))
                                    ? 'No Data Found'
                                    : new Date(selectedImage?.timestamps).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                      })
                                }`}
                              </Typography>
                            </div>
                            <IconButton
                              className="absolute text-white left-[4%] top-[3%] xl:hidden"
                              size="large"
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MenuRounded />
                            </IconButton>
                            <Menu
                              className="absolute"
                              id="long-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                              // PaperProps={{
                              //   style: {
                              //     maxHeight: ITEM_HEIGHT * 4.5,
                              //     width: '20ch'
                              //   }
                              // }}
                            >
                              <MenuList dense>
                                <MenuItem>
                                  {/* TOGGLE BUTTON GROUP FOR LIVE AND REFERENCE IMAGE */}
                                  <ToggleButtonGroup
                                    color="primary"
                                    value={liveImg}
                                    exclusive
                                    onChange={handleToggleImage}
                                    aria-label="Platform"
                                    className="text-white bg-white"
                                    size="small"
                                  >
                                    <ToggleButton
                                      value={true}
                                      style={{
                                        backgroundColor: liveImg ? 'rgb(16, 185, 129)' : '',
                                        color: liveImg ? 'white' : '#10b981',
                                        borderColor: '#10b981',
                                        width: 100
                                      }}
                                    >
                                      Live
                                    </ToggleButton>
                                    <ToggleButton
                                      value={false}
                                      style={{
                                        backgroundColor: !liveImg ? 'rgb(16, 185, 129)' : '',
                                        color: !liveImg ? 'white' : '#10b981',
                                        borderColor: '#10b981',
                                        width: 100
                                      }}
                                    >
                                      Reference
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <Button className="bg-emerald-500 text-white hover:bg-emerald-600 " onClick={handleSaveForRetraining}>
                                    Save for Retraining
                                  </Button>
                                  <Toaster />
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <ListItemText>
                                    <Typography variant="h5">
                                      {layoutData?.store_id} - {layoutData?.name}
                                    </Typography>
                                    <Typography variant="h5">
                                      {currentBay?.bay_name} / {currentShelf?.shelf_name}
                                    </Typography>
                                  </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <ListItemText>
                                    <Typography variant="h5">{`OSA score: ${Math.floor(selectedImage?.avg_full) || 0}%`}</Typography>

                                    <Typography variant="h5">{`VM score: ${Math.floor(selectedImage?.vm_score) || 0}%`}</Typography>
                                  </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <ListItemText>
                                    <Typography variant="h5">{`Name: ${selectedImage?.userDetails?.user_name}`}</Typography>
                                    <Typography variant="h5">{`Number: ${selectedImage?.userDetails?.number}`}</Typography>
                                    <Typography variant="h5">{`Date: ${selectedImage?.timestamps?.split('T')[0]}`}</Typography>
                                    <Typography variant="h5">
                                      {`Time: ${new Date(selectedImage?.timestamps).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                      })}`}
                                    </Typography>
                                  </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <div className="flex flex-col">
                                    <Typography variant="h3" className="text-black m-1">
                                      Anomalies
                                    </Typography>
                                    {/* <Divider   /> */}
                                    <div className="flex flex-wrap w-10/12 gap-2">
                                      {selectedImage?.anomalies_detals?.anomalies_found.map((item, index) =>
                                        item.details.map((itm, ind) => (
                                          <Tooltip
                                            key={index + ind}
                                            title={
                                              <div>
                                                <Typography variant="body1">
                                                  Category: {itm.category ? itm.category : 'No Data Found'}
                                                </Typography>
                                                <Typography variant="body1">
                                                  Intensity: {itm.intensity ? itm.intensity : 'No Data Found'}
                                                </Typography>
                                                <Typography variant="body1">
                                                  Color: {itm.color_family ? itm.color_family : 'No Data Found'}
                                                </Typography>
                                              </div>
                                            }
                                          >
                                            <Box
                                              paddingX={0.2}
                                              paddingY={0.04}
                                              className="bg-gray-200 rounded-full flex gap-1 justify-center place-items-center cursor-pointer align-middle text-center hover:bg-amber-500"
                                              onMouseOver={() => {
                                                calculate(itm.xmin, itm.ymin, itm.xmax, itm.ymax);
                                                setAnomalyclr(true);
                                              }}
                                              onMouseOut={() => {
                                                if (antn) {
                                                  setPos([]);
                                                  setAntn(!antn);
                                                }
                                                setAnomalyclr(false);
                                              }}
                                            >
                                              <RiErrorWarningLine className="text-4xl mr-0.5 text-purple-500" />
                                              <Typography paddingRight={2} variant="h6">
                                                {/* {anomalyType.charAt(0).toUpperCase() + anomalyType.substring(1, anomalyType.indexOf('_'))} */}
                                                {item.type.charAt(0).toUpperCase() + item.type.substring(1, item.type.indexOf('_'))}
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                        ))
                                      )}
                                    </div>
                                  </div>
                                </MenuItem>
                                <MenuItem>
                                  {/* <div
                                    className="  border-red-500 w-64 h-40 grid text-black gap-2 "
                                    style={{
                                        gridTemplateColumns: `repeat(${selectedImage?.tray_detail?.length}, 1fr)`,
                                        gridTemplateRows: `repeat(${selectedImage?.tray_detail?.[0]?.length}, 1fr)`
                                    }}
                                    >
                                    {selectedImage?.tray_detail?.length > 0 &&
                                        selectedImage?.tray_detail?.map((item, index) => {
                                        return (
                                            <div key={index} className="flex flex-col h-40">
                                            {item?.map((value, i) => {
                                                // console.log(value?.rgb);
                                                return (
                                                <Box
                                                    className={` border-black border flex justify-center items-center col-span-1 row-span-1 w-full h-full`}
                                                    style={{ backgroundColor: value?.rgb ? `transparent` : 'transparent' }}
                                                    // style={{ backgroundColor: value?.rgb ? `rgb(${value?.rgb})` : 'transparent' }}
                                                    key={i}
                                                >
                                                    {value?.rgb ? (
                                                    <span className="text-base">{value?.color_family}</span>
                                                    ) : (
                                                    <span className="text-base">Failed to extract</span>
                                                    )}
                                                </Box>
                                                );
                                            })}
                                            </div>
                                        );
                                        })}
                                    </div> */}
                                  <div className=" border-red-500  w-72 overflow-y-scroll scrollbar  ">
                                    {selectedImage &&
                                      selectedImage.tray_detail &&
                                      selectedImage.tray_detail.map((item, index) => (
                                        <Intensity key={index} dta={item} ind={index} mob={true} calculate={calculate} />
                                      ))}
                                  </div>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                            {/* <div
                                className="  border-red-500 absolute right-[7%] top-1/2 -translate-y-1/2 w-96 h-96 xl:grid text-white gap-4 hidden"
                                style={{
                                gridTemplateColumns: `repeat(${selectedImage?.tray_detail?.length}, 1fr)`,
                                gridTemplateRows: `repeat(${selectedImage?.tray_detail?.[0]?.length}, 1fr)`
                                }}
                            >
                                {selectedImage?.tray_detail?.length > 0 &&
                                selectedImage?.tray_detail?.map((item, index) => {
                                    return (
                                    <div key={index} className="flex flex-col h-96">
                                        {item?.map((value, i) => {
                                        // console.log(value?.rgb);
                                        return (
                                            <Box
                                            className={` border-white border flex justify-center items-center col-span-1 row-span-1 w-full h-full`}
                                            style={{ backgroundColor: value?.rgb ? `transparent` : 'transparent' }}
                                            // style={{ backgroundColor: value?.rgb ? `rgb(${value?.rgb})` : 'transparent' }}
                                            key={i}
                                            >
                                            {value?.rgb ? (
                                                <span className="text-lg">{value?.color_family}</span>
                                            ) : (
                                                <span className="text-lg">Failed to extract</span>
                                            )}
                                            </Box>
                                        );
                                        })}
                                    </div>
                                    );
                                })}
                                (<Box
                            className="border border-red-500 flex justify-center items-center col-span-1 row-span-1"
                            ></Box>)
                            </div> */}
                            <div className=" border-red-500 absolute right-[7%] top-[55vh] -translate-y-1/2 w-96   text-white overflow-y-scroll scrollbar h-[80vh] hidden xl:block">
                              {selectedImage &&
                                selectedImage.tray_detail &&
                                selectedImage.tray_detail.map((item, index) => (
                                  <Intensity key={index} dta={item} ind={index} mob={false} calculate={calculate} />
                                ))}
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
}
