import React from 'react';
import { lazy } from 'react';

// project imports
import Loadable from 'component/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('../views/Login')));
// const AuthRegister = Loadable(lazy(() => import('../views/Register')));

// ==============================|| AUTHENTICATION ROUTES ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <AuthLogin />
  // children: [
  //   {
  //     path: '/login',
  //     element: <AuthLogin />
  //   },
  //   {
  //     path: '/register',
  //     element: <AuthRegister />
  //   }
  // ]
};

export default AuthenticationRoutes;
