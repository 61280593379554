import { Box, Grid, Paper, Stack, Typography, Tooltip, Skeleton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RouteIcon from '@mui/icons-material/Route';
import { GetBayWiseAnomalies } from '../../../../api/index';

export default function Uniquejourney({ date }) {
  const [uniquejourneys, setUniquejourneys] = useState('');
  const [journeyData, setJourneyData] = useState(false);
  const [bayAnomalies, setBayAnomalies] = useState('');
  const [bayAnomaliesData, setBayAnomaliesData] = useState([]);

  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;

  useEffect(() => {
    async function getData2() {
      const body = {
        dates: date,
        store_id: analysisStoreId
      };
      try {
        const response = await GetBayWiseAnomalies(body);
        const data = response.data;
        // console.log("anomaliesss", data);

        const lastElement = data.length - 1;
        // console.log("u2", data[lastElement]);
        const u = data[lastElement] ? data[lastElement].anomalies : null;
        // console.log("u", u);
        setBayAnomalies(u);
        setBayAnomaliesData([...data]);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData2();
  }, [date, analysisStoreId]);

  // console.log("bay", bayAnomalies);
  // console.log("bay2", bayAnomaliesData);

  useEffect(() => {
    async function getData() {
      const body = {
        date: date
      };
      try {
        const data = await uniqueJourney(body);
        const u = await data.length;
        setUniquejourneys(u);
        setJourneyData([...data]);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, [date]);

  journeyData && journeyData.sort((a, b) => b.total_customers - a.total_customers);

  return (
    <>
      {journeyData.length > 0 ? (
        <Box className="flex flex-col w-full gap-1 p-3">
          <Stack direction={'row'} className="flex  gap-2">
            {uniquejourneys ? (
              <RouteIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" style={{ transform: 'rotate(45deg)' }} />
            ) : (
              <Skeleton variant="circular" width={45} height={45} />
            )}
            <Stack direction={'column'} alignItems="flex-start">
              {uniquejourneys ? (
                <p className="text-3xl">{uniquejourneys}</p>
              ) : (
                <Skeleton variant="rectangular" className="mb-3 rounded-sm" width={50} height={15} />
              )}
              {uniquejourneys ? (
                <p className="text-lg font-semibold">Total Anomalies</p>
              ) : (
                <Skeleton variant="rectangular" width={150} height={15} className=" mb-5 rounded-sm" />
              )}
            </Stack>
          </Stack>
          {uniquejourneys ? (
            <Box sx={{ overflowX: 'hidden' }} className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] scrollbar rounded-lg p-2.5">
              {journeyData.map((j, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    spacing={1}
                    style={{
                      marginLeft: '5.5%',
                      marginTop: '5px',
                      marginBottom: '10px',
                      flexWrap: 'nowrap',
                      width: '97%',
                      overflowY: 'hidden',
                      overflowX: 'auto'
                    }}
                  >
                    <Stack direction={'Column'} width="100%">
                      <Typography variant="body1" color="initial">
                        {j.total_customers} Customers
                      </Typography>
                      <Stack direction={'row'} width="100%">
                        {j.unique_path.map((z, ind) => {
                          return (
                            <Grid key={ind} item xs={12} sm={6} md={1.5} sx={{ position: 'relative' }}>
                              <Tooltip title={z.name} placement="top">
                                <Paper
                                  style={{
                                    padding: '8px',
                                    display: 'flex',
                                    borderRadius: '50%',
                                    height: '3%',
                                    width: '1%',
                                    backgroundColor: `${z.colourHex}`,
                                    borderColor: '#00b3b3',
                                    position: 'relative',
                                    zIndex: '2'
                                  }}
                                ></Paper>
                              </Tooltip>
                              {ind < j.unique_path.length - 1 && (
                                <Paper
                                  sx={{
                                    position: 'relative',
                                    flexGrow: '1',
                                    top: '-60%',
                                    transform: 'translateX(95%)',
                                    width: '50%',
                                    height: '10%',
                                    backgroundColor: 'grey',
                                    content: '""',
                                    display: 'block',
                                    borderRadius: '0'
                                  }}
                                />
                              )}
                            </Grid>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Grid>
                );
              })}
            </Box>
          ) : (
            <Skeleton variant="rectangular" height={184} className="rounded-md" />
          )}
        </Box>
      ) : (
        <div className="flex  w-full  flex-col gap-1 p-3">
          <div className="flex items-center justify-center gap-2 w-full">
            <RouteIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" style={{ transform: 'rotate(45deg)' }} />

            <div className="w-full">
              <p className="text-3xl text-gray-500 ">{bayAnomalies}</p>
              <p className="text-lg font-semibold">Total Anomalies</p>
            </div>
          </div>
          <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
            {bayAnomaliesData.length === 0 ? (
              <p className="text-base font-semibold text-gray-500">Currently No data available</p>
            ) : (
              bayAnomaliesData.map((item, index) => {
                const percentage = item.anomalies != undefined ? Math.round(parseFloat(item.anomalies)) : 0;
                const barcolor = percentage === 0 ? '#00ac69' : '#ff413a';
                return (
                  <div key={index} className="mt-2">
                    <div className="flex gap-1 items-center">
                      <div>
                        {item.bay_name} :
                        <span className="text-base font-semibold" style={{ color: barcolor }}>
                          {' '}
                          {percentage} %
                        </span>
                      </div>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={percentage}
                      className="rounded-lg"
                      sx={{
                        marginTop: '5px',
                        backgroundColor: 'white',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: `${barcolor}`
                        }
                      }}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
}
