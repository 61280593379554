import { useTheme } from '@emotion/react';
import { Close, Delete, ThumbUpSharp } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageListItemBar,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { getAnomalyBays, getBayAnomalyList, SendAlert, UpdateMetadataStatus, UpdateMetadataStatusIgnore } from 'api';
import { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const StyledButton = styled(Button)(({ theme, isActive }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  transition: 'all 0.3s ease',
  backgroundColor: isActive ? theme.palette.primary.main + '!important' : 'transparent',
  color: isActive ? '#fff !important' : theme.palette.secondary.main,
  border: isActive ? 'none' : `2px solid ${theme.palette.secondary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transform: 'translateY(-2px)'
  }
}));

const StyledCard = styled(Card)(() => ({
  borderRadius: '12px',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const StyledChip = styled(Chip)(() => ({
  margin: '2px',
  fontWeight: 'bold'
}));
const Anomalies = () => {
  const theme = useTheme();
  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;
  const [activeButton, setActiveButton] = useState(0);
  const [bayList, setBayList] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [alertLoading, setAlertLoading] = useState(false);
  const handleButtonClick = async (index, baysDetails) => {
    setActiveButton(index);
    const data = await getBayAnomalyList({ bay_id: baysDetails._id });
    setCardData(data.data);
  };
  const user_id = JSON.parse(localStorage.getItem('userData'))._id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnomalyBays({
          store: analysisStoreId
        });
        console.log('This is the bay list now', data);
        setBayList(data.data);
      } catch (error) {
        console.log('Error fetching the anomaly bay list', error);
      }
    };

    fetchData();
  }, [analysisStoreId]);

  useEffect(() => {
    const fetchDataForCards = async () => {
      if (bayList.length > 0) {
        try {
          const data = await getBayAnomalyList({ bay_id: bayList[0]._id });
          setCardData(data.data);
        } catch (error) {
          console.log('Error fetching the anomaly bay list', error);
        }
      }
    };

    fetchDataForCards();
  }, [bayList]);
  const handleAlertClick = async (id, item) => {
    const comment = newComments[id];
    const resultAnomaly = item.anomalies.anomalies_found
      .map((item) => item.type.split('_')[0].charAt(0).toUpperCase() + item.type.split('_')[0].slice(1))
      .reverse()
      .join(', ');
    if (comment) {
      const API_KEY =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NWY2MmE5Yzk4Nzk3MGFlZWM1ZTg0MCIsIm5hbWUiOiJOZW9QaHl0ZSIsImFwcE5hbWUiOiJBaVNlbnN5IiwiY2xpZW50SWQiOiI2NTVmNjJhOGM5ODc5NzBhZWVjNWU4M2IiLCJhY3RpdmVQbGFuIjoiQkFTSUNfTU9OVEhMWSIsImlhdCI6MTcwMDc0OTk5M30.8-SugzKOaRlF3BFhgTn944znZnsydeoUPudFEIZdNWs'; // Replace with your actual API key
      const API_URL = 'https://backend.aisensy.com/campaign/t1/api/v2';
      const formattedData = {
        apiKey: API_KEY,
        campaignName: 'disha_fashion_alert_campaign_prod',
        destination: item.user_number,
        userName: item.user_name,
        templateParams: ['$AgentName', '$BayId', '$ShelfId', '$AnomaliesTypes', '$BayId', '$ShelfId', '$CustomMessage'],
        tags: ['AgentName', 'BayId', 'ShelfId', 'AnomaliesTypes', 'BayId', 'ShelfId', 'CustomMessage'],
        attributes: {
          AgentName: item.user_name,
          BayId: item.bay_name,
          ShelfId: item.shelf_name,
          AnomaliesTypes: resultAnomaly,
          //eslint-disable-next-line
          BayId: item.bay_name,
          //eslint-disable-next-line
          ShelfId: item.shelf_name,
          CustomMessage: comment
        }
      };
      setAlertLoading(true);
      try {
        const status = await SendAlert(formattedData, API_KEY, API_URL);
        if (status == true) handleCommentChange(id, '');
      } catch (error) {
        console.log('Error occured', error);
      } finally {
        setAlertLoading(false);
      }
    } else {
      console.log('No comment added');
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [newComments, setNewComments] = useState({});
  const handleCommentChange = (id, value) => {
    setNewComments((prev) => ({ ...prev, [id]: value }));
  };

  const updateMetadataSolved = async (id, comment) => {
    setAlertLoading(true);
    try {
      const comments = newComments[comment];
      const solved_time = new Date();
      const response = await UpdateMetadataStatus(id, comments, user_id, solved_time);
      if (response) {
        console.log('AnomalyDetails api', response);
        handleCommentChange(comment, '');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAlertLoading(false);
    }
  };
  const updateMetadataIgnored = async (id) => {
    setAlertLoading(true);
    try {
      const ignored_time = new Date();
      const response = await UpdateMetadataStatusIgnore(id, true, user_id, ignored_time);
      if (response) {
        console.log('AnomalyDetails api', response);
        setAlertLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAlertLoading(false);
    }
  };

  const [antn, setAntn] = useState(false);
  const [pos, setPos] = useState(false);
  const [natural, setNaturel] = useState({ wdth: false, hght: false });
  const calculate = (xmin, ymin, xmax, ymax) => {
    console.log('mindatas', xmin, ymin, xmax, ymax);
    const lft = (xmin / natural.wdth) * 100;
    const top = (ymin / natural.hght) * 100;
    const width = ((xmax - xmin) / natural.wdth) * 100;
    const height = ((ymax - ymin) / natural.hght) * 100;
    setAntn(true);
    return { lft: lft, tp: top, wdth: width, hght: height };
  };

  const findDimensions = (event) => {
    // setImageLoading(false);
    const { naturalWidth, naturalHeight } = event.target;
    setNaturel({ wdth: naturalWidth, hght: naturalHeight });
  };

  const getPos = (anmdata) => {
    if (anmdata) {
      const positions = anmdata.map((item) => calculate(item.xmin, item.ymin, item.xmax, item.ymax));
      setPos(positions);
      return positions;
    }
    return false;
  };

  return (
    <Grid container spacing={3} sx={{ padding: '30px' }}>
      <Grid item xs={12}>
        <Paper
          elevation={6}
          sx={{
            padding: '40px',
            borderRadius: '20px',
            background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
            boxShadow: theme.shadows[10]
          }}
        >
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2} mb={5}>
            {bayList.map((item, i) => (
              <StyledButton
                key={i}
                onClick={() => handleButtonClick(i, item)}
                variant={activeButton === i ? 'contained' : 'outlined'}
                color={activeButton === i ? 'primary' : 'secondary'}
                isActive={activeButton === i}
              >
                {item.bay_name.toUpperCase()}
              </StyledButton>
            ))}
          </Box>

          <TableContainer component={Paper} sx={{ borderRadius: '15px', overflow: 'hidden', boxShadow: theme.shadows[3] }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Image</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Details</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Comments</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardData.map((item, ind) => (
                  <TableRow key={ind} hover sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                    <TableCell>
                      <StyledCard>
                        <CardMedia
                          component="img"
                          image={item.image_url}
                          alt={item.shelf_name}
                          sx={{ height: 150, cursor: 'pointer' }}
                          onClick={() => setSelectedImage(item)}
                        />
                      </StyledCard>
                    </TableCell>
                    <TableCell>
                      <CardContent>
                        <Typography variant="h6" color="primary" gutterBottom>
                          {item.shelf_name}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          {item.part_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Date: {new Date(item.anomalies.timestamps).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Days until resolved: {item.days_until_resolved}
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {item.anomalies.anomalies_found.map((tag, index) => (
                            <StyledChip key={index} label={tag.type.split('_')[0].toUpperCase()} color="error" />
                          ))}
                        </Box>
                      </CardContent>
                    </TableCell>
                    <TableCell>
                      <TextField
                        placeholder="Add a comment..."
                        variant="outlined"
                        value={newComments[ind] || ''}
                        onChange={(e) => handleCommentChange(ind, e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                      <Button
                        onClick={async () => await handleAlertClick(ind, item)}
                        disabled={!newComments[ind] || alertLoading}
                        variant="contained"
                        fullWidth
                      >
                        {alertLoading ? (
                          <div>
                            <CircularProgress size={20} />
                          </div>
                        ) : (
                          'Alert Store'
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Button
                          onClick={async () => await updateMetadataSolved(item.anomalies.analysis_id, ind)}
                          disabled={!newComments[ind] || alertLoading}
                          variant="outlined"
                          fullWidth
                          startIcon={<ThumbUpSharp />}
                        >
                          {alertLoading ? (
                            <div>
                              <CircularProgress size={20} />
                            </div>
                          ) : (
                            'Solved'
                          )}
                        </Button>
                        <Button
                          onClick={async () => await updateMetadataIgnored(item.anomalies.analysis_id)}
                          variant="outlined"
                          disabled={alertLoading}
                          color="error"
                          fullWidth
                          startIcon={<Delete />}
                        >
                          {alertLoading ? (
                            <div>
                              <CircularProgress size={20} />
                            </div>
                          ) : (
                            'Ignore'
                          )}
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} fullWidth>
            {selectedImage && (
              <>
                <DialogTitle
                  sx={{
                    m: 0,
                    p: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                  }}
                >
                  {selectedImage.shelf_name} || {selectedImage.part_name}
                  <IconButton
                    aria-label="close"
                    onClick={() => setSelectedImage(null)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: theme.palette.primary.contrastText
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Box display="flex" gap={3} alignItems="flex-start">
                    <Box flex={2} display="flex" justifyContent="center" alignItems="center">
                      <TransformWrapper>
                        <div className="image-wrapper rounded-md md:w-full">
                          <TransformComponent>
                            <div style={{ position: 'relative' }}>
                              <img src={selectedImage.image_url} alt={selectedImage.shelf_name} onLoad={findDimensions} />

                              {antn &&
                                pos?.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      position: 'absolute',
                                      left: `${item.lft}%`,
                                      top: `${item.tp}%`,
                                      width: `${item.wdth}%`,
                                      height: `${item.hght}%`,
                                      border: '1px solid red',
                                      boxSizing: 'border-box',
                                      pointerEvents: 'none',
                                      backgroundColor: 'rgba(255, 0, 0, 0.6)',
                                      borderRadius: '5px'
                                    }}
                                  ></div>
                                ))}
                            </div>

                            <ImageListItemBar title={`Date: dfa`} subtitle={`Time: sdasj`} />
                          </TransformComponent>
                        </div>
                      </TransformWrapper>
                    </Box>

                    <Box flex="1" display="grid" gap={3}>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          Time
                        </Typography>
                        <Typography>{new Date(selectedImage.anomalies.timestamps).toLocaleDateString()}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          Anomalies
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {selectedImage.anomalies.anomalies_found.map((tag, index) => (
                            <StyledChip
                              key={index}
                              label={tag.type.split('_')[0].toUpperCase()}
                              color="error"
                              onMouseOver={() => {
                                getPos(tag.details);
                              }}
                              onMouseOut={() => {
                                if (antn) {
                                  setPos(false);
                                  setAntn(false);
                                }
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
                  <Button onClick={() => setSelectedImage(null)} color="primary">
                    Close
                  </Button>
                  <Button
                    onClick={() => updateMetadataIgnored(selectedImage.anomalies.analysis_id)}
                    disabled={alertLoading}
                    color="error"
                    startIcon={<Delete />}
                  >
                    {alertLoading ? (
                      <div>
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      'Ignore'
                    )}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Anomalies;
