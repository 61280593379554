import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import './custom-driver.css';
export const driverJsInit = () => {
  const productTourSeen = localStorage.getItem('product_tour_seen') === 'true';

  if (productTourSeen) {
    return;
  }

  const driverObj = driver({
    doneBtnText: 'Finish',
    showProgress: true,
    allowClose: true,
    steps: [
      {
        element: '.filter',
        popover: {
          title: 'Filter Your Data',
          description: 'Easily sort your data by cluster manager, zones, and more to get precise insights.'
        }
      },
      {
        element: '.calendar',
        popover: {
          title: 'Calendar',
          description: 'Select a date to view the corresponding data and trends.'
        }
      },
      {
        element: '.profile',
        popover: {
          title: 'Profile Section',
          description: 'Access your profile details and log out options with ease.'
        }
      },
      {
        element: '.osa',
        popover: {
          title: 'On Shelf Assessment',
          description: 'Hover over the line chart to view the OSA score for the past week.'
        }
      },
      {
        element: '.spike',
        popover: {
          title: 'Check Stats',
          description: 'Monitor the rise or fall in stats to stay updated on performance.'
        }
      },
      {
        element: '.vm',
        popover: {
          title: 'Visual Merchandising',
          description: 'Hover over the line chart to see the VM score for the last 7 days.'
        }
      },
      {
        element: '.pop',
        popover: {
          title: 'Place of Purchase',
          description: 'View the PoP score for the last 7 days by hovering over the line chart.'
        }
      },
      {
        element: '.anomalies',
        popover: {
          title: 'Anomalies Found',
          description: 'Check for anomalies detected over the past week by hovering over the line chart.'
        }
      },
      {
        element: '.barGraph',
        popover: {
          title: 'Anomalies Found',
          description: 'Hover over the line chart to see anomalies detected over the last 7 days.'
        }
      },
      {
        element: '.graphOptions',
        popover: {
          title: 'Select a Filter',
          description: 'Use the dropdown menu to filter your graph by the required KPI.'
        }
      },
      {
        element: '.brandFullness',
        popover: {
          title: 'Brand Fullness',
          description: 'Check brand fullness across different stores.'
        }
      },
      {
        element: '.captureProgress',
        popover: {
          title: 'Capture Progress',
          description: 'Track the capture progress of stores along with individual store progress.'
        }
      },
      {
        element: '.anomaliesFound',
        popover: {
          title: 'Anomalies Found/Resolved',
          description: 'Visualize anomalies found and resolved in a comprehensive bar graph.'
        }
      }
    ],
    onDestroyed: () => {
      localStorage.setItem('product_tour_seen', 'true');
    }
  });

  driverObj.drive();
};

export const driverJsInitStores = () => {
  const productTourStores = localStorage.getItem('product_tour_seen_stores') === 'true';
  if (productTourStores) {
    return;
  }
  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '.search',
        popover: {
          title: 'Search',
          description: "Search for a specific store, based on it's name or store ID."
        }
      },
      {
        element: '.addStore',
        popover: {
          title: 'Add your Store',
          description: "Opens a pop up that let's you onboard a new store."
        }
      },
      {
        element: '.store',
        popover: {
          title: 'Store wise analysis',
          description: 'View all the stores as cards and they give you info.'
        }
      },
      {
        element: '.map',
        popover: {
          title: 'Map View',
          description: 'View the location of the store on maps.'
        }
      },
      {
        element: '.storeStatus',
        popover: {
          title: 'Store Status',
          description: 'Shows the status of the store.'
        }
      },
      {
        element: '.storeName',
        popover: {
          title: 'Store Name',
          description: 'Shows you the name of the store.'
        }
      },
      {
        element: '.kpiScore',
        popover: {
          title: 'KPI Stats',
          description: "View the KPI's for each store in a single glance."
        }
      },
      {
        element: '.analysis',
        popover: {
          title: 'Store Analysis',
          description: 'Analyse each store individually for more detailed bay wise analysis.'
        }
      },
      {
        element: '.anomaliesFoundResolved',
        popover: {
          title: 'Anomalies Resolves/Found',
          description: 'View the total number of anomalies found and how many of it is resolved.'
        }
      },
      {
        element: '.agents',
        popover: {
          title: 'View Agents',
          description: 'Hover and view the agents who have captured the images.'
        }
      },
      {
        element: '.anomalyImages',
        popover: {
          title: 'View the Images with anomaly',
          description: 'View the images having the anomalies, click on it to get a detailed analysis on what causes the anomaly.'
        }
      }
    ],
    onDestroyed: () => {
      localStorage.setItem('product_tour_seen_stores', 'true');
    }
  });
  driverObj.drive();
};
export const driverJsInitTeams = () => {
  const productTourTeams = localStorage.getItem('product_tour_seen_teams') === 'true';
  if (productTourTeams) {
    return;
  }
  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '.addUsers',
        popover: {
          title: 'Add User',
          description: 'Click on it to add new users to your team.'
        }
      },
      {
        element: '.filterTable',
        popover: {
          title: 'Filter Users',
          description: 'On the basis of roles assigned, filter the users.'
        }
      },
      {
        element: '.search',
        popover: {
          title: 'Simplify Search',
          description: 'Search based on the user name to retrieve data.'
        }
      },
      {
        element: '.export',
        popover: {
          title: 'Export Data',
          description: 'Export and save the information related to your team.'
        }
      },
      {
        element: '.table',
        popover: {
          title: 'View Table',
          description: 'View all your users, with the applied filter, on this table. Modify, edit, and delete these users using this table.'
        }
      }
    ],
    onDestroyed: () => {
      localStorage.setItem('product_tour_seen_teams', 'true');
    }
  });
  driverObj.drive();
};
