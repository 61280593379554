/* eslint-disable*/
import { Grid, useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { GetBayWiseDetails /*GetStoreLayout*/ } from 'api';
import { bouncy } from 'ldrs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import toast, { Toaster } from 'react-hot-toast';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';

bouncy.register();

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#101010bf'
        }
      }
    }
  }
});
const StoreView2 = ({ changeView, setBaysDetails }) => {
  const [loading, setLoading] = useState(true);
  //const xDate = useSelector((state) => state.customization.date);
  // const [bayToOpen, setBayToOpen] = useState({});
  const [openShelves, setOpenShelves] = useState(false);
  const [openBay, setOpenBay] = useState(false);

  const imageRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  //const isMdOrLarger = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isSmOrLarger = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isLgOrLarger = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [layoutData, setLayoutData] = useState({});

  //const date = new Date();
  const date = useSelector((state) => state.customization.selectedDate)
    .toISOString()
    .slice(0, 10);
  //const today = date.toISOString().split('T')[0];
  const { storeId } = useParams();

  //const [selectedDate /*setSelectedDate*/] = useState(new Date().toISOString().slice(0, 10)); // Sets initial value to today's date

  const getLayoutData = async () => {
    // const input = {
    //   Store_IDs: ['6582be9ac5ed94d792a563b8'],
    //   // start_date: '2024-01-01'
    //   start_date: today
    // };
    // const response = await GetStoreLayout(input);
    const response = await GetBayWiseDetails(date, storeId);
    // console.log(response.data[0]);
    setLayoutData(response.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    getLayoutData();
  }, [date]);
  const findMidpoint = (coordinates, dimensions) => {
    const { x, y } = coordinates;
    const { width, height } = dimensions;
    const midPoint = { x: x + width / 2, y: y + height / 2 };
    return midPoint;
  };

  const findDimensions = (event) => {
    setLoading(false);
    const { naturalWidth } = event.target;
    const imgDiv = imageRef.current;
    const { width } = imgDiv.getBoundingClientRect();
    setScaleFactor(width / naturalWidth);
  };
  return (
    <>
      <>
        {loading ? (
          <div
            style={{
              alignContent: 'center',
              display: 'flex',
              flexGrow: '1',
              padding: '6%',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <l-bouncy size="45" speed="1.75" color="black"></l-bouncy>
          </div>
        ) : (
          <Grid
            item
            sx={{ paddingTop: '1%', padding: '2%', height: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto' }}
            className="scrollbar"
          >
            {!openBay && !openShelves && (
              <TransformWrapper>
                <TransformComponent>
                  <div
                    className={`w-full h-full relative border-red-500  md:rotate-0 rotate-90 flex justify-start items-center scale-[1.5] md:scale-100 md:top-0 top-48`}
                  >
                    <img
                      src={layoutData?.image_url}
                      alt="layout"
                      loading="lazy"
                      onLoad={findDimensions}
                      ref={imageRef}
                      className="lg:h-full opacity-50"
                    />
                    <div className="absolute top-0 left-0 w-full h-full">
                      {layoutData?.bayDetails?.map((item, index) => {
                        if (item.type !== 'beauty') {
                          return (
                            <ThemeProvider theme={theme} key={index}>
                              <Tooltip
                                title={
                                  <div className="flex flex-col">
                                    <span>Brand: {item?.brand_name || ''}</span>
                                    <span>OSA: {isNaN(item?.bay_fullness) ? 'No Capture' : Math.floor(item?.bay_fullness) + '%'}</span>
                                    <span>
                                      {/* Timestamp: {item?.timestamps || ''} */}
                                      {`Date: ${item?.timestamps?.split('T')[0]}`}
                                    </span>
                                    <span>
                                      {`Time: ${new Date(item?.timestamps).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                      })}`}
                                    </span>
                                  </div>
                                }
                              >
                                <button
                                  key={index}
                                  className={`absolute lg:px-2 xl:px-4 xl:text-base ${
                                    item?.bay_fullness >= 80
                                      ? 'bg-emerald-500'
                                      : item?.bay_fullness >= 50 && item?.bay_fullness < 80
                                      ? 'bg-orange-500'
                                      : item?.bay_fullness < 50
                                      ? 'bg-red-600'
                                      : 'bg-gray-500 disabled:'
                                  } rounded-lg text-xs  h-4 md:h-10 text-white md:rotate-0 md:px-2 px-1 sm:py-1 sm:px-1  md:py-1 lg:py-2 lg:px-2 xl:py-1 xl:px-1`}
                                  style={{
                                    top: isSmOrLarger
                                      ? `${findMidpoint(item.coordinates, item.dimensions).y * scaleFactor}px`
                                      : `${findMidpoint(item.coordinates, item.dimensions).y * scaleFactor * 1.4}px`,
                                    left: isSmOrLarger
                                      ? `${findMidpoint(item.coordinates, item.dimensions).x * scaleFactor}px`
                                      : `${findMidpoint(item.coordinates, item.dimensions).x * scaleFactor * 1.58}px`
                                    //position: 'absolute'
                                  }}
                                  onClick={() => {
                                    changeView(item);
                                    setBaysDetails(layoutData.bayDetails);
                                    console.log('Hello');
                                  }}
                                >
                                  {isLgOrLarger ? item.bay_name : item.bay_name.split(' ')[1]}
                                </button>
                              </Tooltip>
                            </ThemeProvider>
                          );
                        } else {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                handleBay10Click(layoutData.name);
                              }}
                            >
                              <Toaster />
                              <button
                                key={index}
                                className={`absolute lg:px-2 xl:px-4 xl:text-base ${
                                  item?.bay_fullness >= 80
                                    ? 'bg-emerald-500'
                                    : item?.bay_fullness >= 50 && item?.bay_fullness < 80
                                    ? 'bg-orange-500'
                                    : item?.bay_fullness < 50
                                    ? 'bg-red-600'
                                    : 'bg-gray-500 disabled:'
                                } rounded-lg text-xs  h-4 md:h-10 text-white md:rotate-0 md:px-2 px-1 sm:py-1 sm:px-1  md:py-1 lg:py-2 lg:px-2 xl:py-1 xl:px-1`}
                                style={{
                                  top: isSmOrLarger
                                    ? `${findMidpoint(item.coordinates, item.dimensions).y * scaleFactor}px`
                                    : `${findMidpoint(item.coordinates, item.dimensions).y * scaleFactor * 1.4}px`,
                                  left: isSmOrLarger
                                    ? `${findMidpoint(item.coordinates, item.dimensions).x * scaleFactor}px`
                                    : `${findMidpoint(item.coordinates, item.dimensions).x * scaleFactor * 1.58}px`
                                }}
                              >
                                {isLgOrLarger ? item.bay_name : item.bay_name.split(' ')[1]}
                              </button>
                            </p>
                          );
                        }
                      })}
                    </div>
                  </div>
                </TransformComponent>
              </TransformWrapper>
            )}
          </Grid>
        )}
      </>
    </>
  );
};

export default StoreView2;
