/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography, Card, Skeleton, LinearProgress } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
// import { avgDwelTime } from '../../../api/sentinelAPI';
// import { footfallCard } from '../../../api/sentinelAPI';
// import { getRatio } from 'api/sentinelAPI';
// import NoDataImg from '../../../assets/images/No_data-amico.svg';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
// import DatePickerStore from './Calendar';
import UpdateIcon from '@mui/icons-material/Update';
import Uniquejourney from './KPICards/Uniquejourney';
// import DonutChart from './TrendsViewCharts/DonutChart';
// import DonutChartTwo from './TrendsViewCharts/DonutChartTwo';
// import GroupIcon from '@mui/icons-material/Group';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import LineChartToggle from './lineChartToggle';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Chart from 'react-apexcharts';
import RadarChart from './RadarChart';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GetCaptureProgress, GetBayWiseOSA, GetBayWiseVM, GetSevenDaysOSA, GetAssociateScore, GetCategoryWiseAnomaly } from 'api';

function Overview({ dates }) {
  // console.log('dates', dates);
  const { storeId } = useParams();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [storeDwelTime, setStoreDwelTime] = useState(false);
  const [averageDwellTime, setAverageDwellTime] = useState(false);
  const [dweltimeData, setDweltimedata] = useState(false);
  const [footfalldata, setFootfalldata] = useState(false);
  const [ftfall, setftfall] = useState([]);
  const [osa, setOsa] = useState('');
  const [osaData, setOsaData] = useState([]);
  const [vm, setVm] = useState('');
  const [vmData, setVmData] = useState([]);
  // const [date, setSelectedDate] = useState('');
  const selectedDate = useSelector((state) => state.customization.selectedDate)
    .toISOString()
    .slice(0, 10);
  const [empCount, setEmpCount] = useState('');
  const [costcnt, setCostcnt] = useState('');
  const [ratio, setRatio] = useState('');
  const [capProgress, setCapProgress] = useState(false);
  const [avgCapProgress, setAvgCapProgress] = useState(false);
  const [capProgressValue, setCapProgressValue] = useState(false);
  const [anomaliesLoading, setAnomaliesLoading] = useState(false);
  const [sevenDaysOSA, setSevenDaysOSA] = useState(false);
  const [associateScore, setAssociateScore] = useState('');
  const [associateData, setAssociateData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  console.log(ratio);

  const accentColDark = theme.palette.success.dark;
  const accentColLight = theme.palette.success.light;

  const progressChart = {
    options: {
      chart: {
        height: 180,
        type: 'radialBar',
        sparkline: {
          enabled: true
        }
      },
      colors: [accentColLight],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            padding: 0,
            size: '30%'
            // background: '#293450'
          },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            show: false,
            name: {
              offsetY: -10,
              color: '#fff',
              fontSize: '13px'
            },
            value: {
              color: '#fff',
              fontSize: '30px',
              show: false
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          gradientToColors: [accentColDark],
          stops: [0, 100]
        }
      },
      stroke: {
        // lineCap: 'round'
      }
      // labels: ['Progress']
    },
    series: [68]
  };

  // const calDate = (d) => {
  //   setSelectedDate(d.toString());
  //   return d;
  // };

  let a = localStorage.getItem('userData');
  // storeId = JSON.parse(a).stores;
  let userId = JSON.parse(a).id;

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    // dispatch({ type: 'DATE', date: storeId });

    if (isMounted) {
      const extremeBody = {
        date: selectedDate.toString(),
        user_id: userId,
        city: '',
        state: '',
        zone: ''
      };

      async function getCaptureProgress(commonBody, signal) {
        setCapProgress(false);
        try {
          const data = await GetCaptureProgress(commonBody, { signal });

          if (!signal.aborted) {
            if (data) {
              // if (data.data.length > 0) {
              //   console.log('Cap Progress Data', data);
              //   const totalCapturePercentage = data.data.reduce((acc, item) => acc + item.capture_percentage, 0);
              //   const average = totalCapturePercentage / data.data.length;

              //   setAvgCapProgress(Math.floor(average));
              // } else {
              //   setAvgCapProgress('');
              // }
              const singleCapData = data.data.find((item) => item._id === storeId);
              setAvgCapProgress(singleCapData);
              setCapProgress(data.data);
            }
          }
        } catch (error) {
          if (!signal.aborted) {
            setCapProgress(false);
            console.log(error);
          }
        }
      }

      getCaptureProgress(extremeBody, signal);
      // getBrandDonutData(extremeBody, signal),
      // getBarChartData(extremeBody, signal),
      // getVMscoreBar(extremeBody, signal),
      // getFullnessForOneWeek(extremeBodyPeriod, signal),
      // getVMComplianceForOneWeek(extremeBody, signal),
      // getAnomaliesForOneWeek(extremeBodyPeriod, signal);
    }

    return () => {
      abortController.abort();
    };
  }, [selectedDate, isMounted]);

  useEffect(() => {
    // Set the component to be mounted when the effect is run
    setIsMounted(true);

    // Return a cleanup function to set the component to unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    const commonBody = {
      start_date: selectedDate,
      storeId: '65c5e26a0b5be5dc7af327dc'
    };
    // eslint-disable-next-line
    async function getDataDwell() {
      // console.log(date);
      try {
        // const data = await avgDwelTime(commonBody);
        const data = [];
        console.log('Dwell Time', data);
        // console.log(data.length);
        if (data.length == 0) {
          setStoreDwelTime(false);
          setDweltimedata(false);
        } else if (data.length > 0) {
          const { storeDwellTime } = data;

          const storeAvgDwellTime = storeDwellTime[0].avgDwellTime;

          setStoreDwelTime(storeAvgDwellTime);

          const filteredZoneDwellTime = data.zoneDwellTime.filter((zone) => zone.zoneName !== 'Entry');
          setDweltimedata(filteredZoneDwellTime);

          const avgDwellTimeValues = filteredZoneDwellTime.map((zone) => zone.avgDwellTime);
          const total = avgDwellTimeValues.reduce((acc, val) => acc + val, 0);
          const avg = total / avgDwellTimeValues.length;
          setAverageDwellTime(avg);
        }
        return data;
      } catch (error) {
        console.log(error);
      }
    }
    console.log(commonBody);
    // eslint-disable-next-line
    async function getFootfalldata() {
      try {
        // const data = await footfallCard(commonBody);
        const data = [];
        console.log(data);
        if (data.length == 0) {
          // console.log('hello')
          setFootfalldata(false);
          setftfall(false);
        } else if (data.length > 0) {
          const { totalCustomerStore } = data[0];
          const { zones } = data[0];
          setftfall(zones);
          // console.log(zones);
          setFootfalldata(totalCustomerStore);
        }

        return data;
      } catch (error) {
        console.log(error);
      }
    }
    async function getRatioData() {
      // const body = {
      //   start_date: date,
      //   storeId: '65c5e26a0b5be5dc7af327dc'
      // };
      try {
        // const {
        //   'Customer count': customerCount,
        //   'Employee count': employeeCount,
        //   'Employee to customer ratio': ratio
        // } = await getRatio(body);
        // setEmpCount(employeeCount);
        setEmpCount(15);
        // setCostcnt(customerCount);
        setCostcnt(25);
        // setRatio(ratio);
        setRatio('3:5');
        // const u = await data.length
        // setUniquejourneys(u);
        // setJourneyData([...data]);
        // console.log(journeyData.length)
        // console.log(dta);
        // return ;
      } catch (error) {
        console.log(error);
      }
    }
    if (isMounted) {
      getFootfalldata();
      getDataDwell();
      getRatioData();
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;

  useEffect(() => {
    async function getData2() {
      const body = {
        dates: selectedDate,
        store_id: analysisStoreId
      };
      try {
        const response = await GetBayWiseOSA(body);
        const res = await GetSevenDaysOSA(body);
        const data = response.data;
        const resData = res.data;
        // console.log('osa', data);
        // console.log('pp', resData);

        const lastElement = data.length - 1;
        const lastResElement = resData.length - 1;
        // console.log('osa2', resData[lastResElement]);
        const user = data[lastElement] ? data[lastElement].fullness : null;
        const userRes = resData[lastResElement] ? resData[lastResElement].averageFullness : null;
        // console.log('user', user);
        // console.log('check', userRes);
        setOsa(user);
        setSevenDaysOSA(userRes);
        setOsaData([...data]);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData2();
  }, [selectedDate, analysisStoreId]);

  // console.log('osadata', osa);
  // console.log('osadata2', osaData);
  // console.log('userRes', sevenDaysOSA);

  useEffect(() => {
    async function getData3() {
      const body = {
        dates: selectedDate,
        store_id: analysisStoreId
      };
      // console.log('body', body);
      try {
        const response = await GetBayWiseVM(body);
        const data = response.data;
        // console.log('vm', response);

        const lastElement = data.length - 1;
        // console.log('vm2', data[lastElement]);
        const user = data[lastElement] ? data[lastElement].avgVmScore : null;
        // console.log('users', user);
        setVm(user);
        setVmData(data[lastElement]);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData3();
  }, [selectedDate, analysisStoreId]);

  // console.log('vmdata', vm);
  // console.log('vmdata2', vmData);

  useEffect(() => {
    async function getData4() {
      const body = {
        dates: selectedDate,
        store_id: analysisStoreId
      };
      try {
        const response = await GetAssociateScore(body);
        const data = response.data;
        // console.log('associatess', data);
        setAssociateData(data);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData4();
  }, [selectedDate, analysisStoreId]);

  useEffect(() => {
    async function getData5() {
      const body = {
        dates: selectedDate,
        store_id: analysisStoreId
      };
      try {
        const response = await GetCategoryWiseAnomaly(body);
        const data = response.data;
        // console.log('associatess', data);
        setCategoryData(data);
        return data;
      } catch (error) {
        console.log(error);
      }
    }

    getData5();
  }, [selectedDate, analysisStoreId]);

  // console.log('associatedata', associateData);

  ftfall && ftfall.sort((a, b) => b.totalCustomerZone - a.totalCustomerZone);
  dweltimeData && dweltimeData.sort((a, b) => b.avgDwellTime - a.avgDwellTime);
  console.log('avg cap progress', avgCapProgress, capProgress);
  return (
    <div className="  w-full ">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent={'space-between'}>
            <Typography variant="h3">Overview</Typography>
            {/* <div>
              <DatePickerStore SetSelectedDate={setSelectedDate} style={{ borderRadius: '15px' }} />
            </div> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                {ftfall.length > 0 ? (
                  <div className="flex  w-full  flex-col gap-1 p-3">
                    <div className="flex items-center justify-center gap-2 w-full">
                      {footfalldata ? (
                        <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />
                      ) : (
                        <Skeleton variant="circular" width={60} height={45} />
                      )}
                      <div className="w-full">
                        {footfalldata ? (
                          <p className="text-3xl">{footfalldata}</p>
                        ) : (
                          <Skeleton variant="rectangular" className="mb-3 rounded-sm" width={50} height={20} />
                        )}

                        {footfalldata ? (
                          <p className="text-lg font-semibold">Store Footfall</p>
                        ) : (
                          <Skeleton variant="rectangular" width={150} height={15} className=" mb-2 rounded-sm" />
                        )}
                      </div>
                    </div>
                    {footfalldata ? (
                      <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                        {ftfall.map((item, index) => {
                          const percentage = (item.totalCustomerZone / ftfall[0].totalCustomerZone) * 100;
                          const barcolor = percentage >= 80 ? '#00ac69' : percentage >= 50 ? '#f4a100' : '#ff413a';
                          // console.log(percentage);
                          return (
                            <div className="mt-2" key={index}>
                              <div className="flex gap-1 items-center">
                                <div
                                  className=" rounded-full h-4 w-4"
                                  style={{
                                    backgroundColor: `${item.zoneColourHex}`
                                  }}
                                >
                                  {' '}
                                </div>
                                <div>
                                  {item.zoneName} : {item.totalCustomerZone}
                                </div>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={percentage}
                                sx={{
                                  marginTop: '5px',
                                  backgroundColor: 'rgb(241 245 249)', // Set color for unfilled part
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: `${barcolor}` // Set color for filled part
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Skeleton variant="rectangular" height={184} className="rounded-md" />
                    )}
                  </div>
                ) : (
                  <div className="flex  w-full  flex-col gap-1 p-3">
                    <div className="flex items-center justify-center gap-2 w-full">
                      <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />

                      <div className="w-full">
                        <p className="text-3xl text-gray-500 ">{parseFloat(sevenDaysOSA).toFixed(2)}</p>
                        <p className="text-lg font-semibold">OSA score</p>
                      </div>
                    </div>
                    {/* <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                      <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                    </div> */}
                    <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                      {osaData.length === 0 ? (
                        <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                      ) : (
                        osaData.map((item, index) => {
                          const percentage = item.fullness != undefined ? Math.round(parseFloat(item.fullness)) : 0;
                          const barcolor = percentage === 0 ? '#00ac69' : '#ff413a';
                          return (
                            <div key={index} className="mt-2">
                              <div className="flex gap-1 items-center">
                                <div>
                                  {item.bayName} :
                                  <span className="text-base font-semibold" style={{ color: barcolor }}>
                                    {' '}
                                    {parseFloat(percentage).toFixed(1)} %
                                  </span>
                                </div>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={percentage}
                                className="rounded-lg"
                                sx={{
                                  marginTop: '5px',
                                  backgroundColor: 'white',
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: `${barcolor}`
                                  }
                                }}
                              />
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>

                  // <img src={NoDataImg} style={{height:"100%", width:"100%"}} alt="No data" />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                {dweltimeData.length > 0 || dweltimeData.length === 0 ? (
                  <div className="flex flex-col w-full gap-1 p-3">
                    <div className="flex  gap-2">
                      {storeDwelTime ? (
                        <UpdateIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />
                      ) : (
                        <Skeleton variant="circular" width={45} height={45} />
                      )}
                      <div>
                        {storeDwelTime ? (
                          <p className="text-3xl">{averageDwellTime.toFixed(2)} min</p>
                        ) : (
                          <Skeleton variant="rectangular" className="mb-3 rounded-sm" width={50} height={15} />
                        )}
                        {storeDwelTime ? (
                          <p className="text-lg font-semibold">Avg Dwell Time</p>
                        ) : (
                          <Skeleton variant="rectangular" width={150} height={15} className=" mb-5 rounded-sm" />
                        )}
                      </div>
                    </div>
                    {storeDwelTime ? (
                      <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] scrollbar rounded-lg p-2.5">
                        {dweltimeData.map((item, index) => {
                          const percentage = (item.avgDwellTime / dweltimeData[0].avgDwellTime) * 100;
                          const barcolor = percentage >= 80 ? '#00ac69' : percentage >= 50 ? '#f4a100' : '#ff413a';
                          return (
                            <div className="mt-2" key={index}>
                              <div className="flex gap-1 items-center">
                                <div
                                  className=" rounded-full h-4 w-4"
                                  style={{
                                    backgroundColor: `${item.zoneColourHex}`
                                  }}
                                >
                                  {' '}
                                </div>
                                <div>
                                  {item.zoneName} : {item.avgDwellTime.toFixed(2)} min
                                </div>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={percentage}
                                sx={{
                                  marginTop: '5px',
                                  backgroundColor: 'rgb(241 245 249)', // Set color for unfilled part
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: `${barcolor}` // Set color for filled part
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Skeleton variant="rectangular" height={184} className="rounded-md" />
                    )}
                  </div>
                ) : (
                  // dfhfdjdgretyuiuoiytrretyuk
                  <div className="flex  w-full  flex-col gap-1 p-3">
                    <div className="flex items-center justify-center gap-2 w-full">
                      {/* <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" /> */}
                      <UpdateIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />

                      <div className="w-full">
                        <p className="text-3xl text-gray-500 ">{parseFloat(vm).toFixed(2)}</p>
                        <p className="text-lg font-semibold">VM score</p>
                      </div>
                    </div>
                    <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                      {vmData?.bay_wise && vmData.bay_wise.length > 0 ? (
                        vmData?.bay_wise.map((x, index) => {
                          console.log(x.baywiseVmScore);
                          const percentage = x.baywiseVmScore ? Math.round(parseFloat(x.baywiseVmScore)) : 0;
                          // console.log('hh', percentage);
                          const barcolor = percentage === 0 ? '#00ac69' : '#ff413a';
                          return (
                            <div key={index} className="mt-2">
                              <div className="flex gap-1 items-center">
                                <div>
                                  {x.bayDetails.bay_name} :
                                  <span className="text-base font-semibold" style={{ color: barcolor }}>
                                    {' '}
                                    {parseFloat(percentage).toFixed(1)} %
                                  </span>
                                </div>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={percentage}
                                className="rounded-lg"
                                sx={{
                                  marginTop: '5px',
                                  backgroundColor: 'white',
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: `${barcolor}`
                                  }
                                }}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                      )}
                    </div>
                  </div>

                  // <img src={NoDataImg} style={{height:"100%", width:"100%"}} alt="No data" />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                <Uniquejourney date={selectedDate} />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                <div className="flex  w-full  flex-col gap-1 p-3">
                  <div className="flex items-center justify-center gap-2 w-full">
                    {/* <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" /> */}
                    <UpdateIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />

                    <div className="w-full">
                      <p className="text-3xl text-gray-500 ">NA</p>
                      <p className="text-lg font-semibold">Associate Score</p>
                    </div>
                  </div>
                  {/* <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                    <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                  </div> */}
                  <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                    {associateData.length === 0 ? (
                      <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                    ) : (
                      associateData.map((item, index) => {
                        const percentage = item.associate_score != undefined ? Math.round(parseFloat(item.associate_score)) : 0;
                        const barcolor = percentage === 0 ? '#00ac69' : '#ff413a';
                        return (
                          <div key={index} className="mt-2">
                            <div className="flex gap-1 items-center">
                              <div>
                                {item.user_name} :
                                <span className="text-base font-semibold" style={{ color: barcolor }}>
                                  {' '}
                                  {parseFloat(percentage).toFixed(1)} %
                                </span>
                              </div>
                            </div>
                            <LinearProgress
                              variant="determinate"
                              value={percentage}
                              className="rounded-lg"
                              sx={{
                                marginTop: '5px',
                                backgroundColor: 'white',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: `${barcolor}`
                                }
                              }}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card
                className="border border-gray-300 h-full"
                sx={{
                  padding: '5px'
                }}
                style={{ height: '275px' }}
              >
                <RadarChart date={selectedDate} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid className="mb-10" item xs={12} lg={9} xl={9.6}>
              <Card className="border border-gray-300" sx={{ height: '550px' }}>
                <LineChartToggle dates={selectedDate} />
              </Card>
            </Grid>
            <Grid item className="mb-10" xs={12} lg={3} xl={2.4}>
              {/* <Card className="border border-gray-300" sx={{ height: '550px' }}>
                <div
                  style={{
                    padding: '10px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem'
                  }}
                >
                  <QuestionAnswerIcon className="bg-[#444444] text-white rounded-full p-2 text-5xl" />
                  <div className="flex flex-col items-start pt-1">
                    <h3 className="text-4xl">12</h3>
                    <p>Message Logs</p>
                  </div>
                </div>
                <Card
                  className=" rounded-lg bg-slate-700 text-white w-[95%] mx-auto"
                  sx={{
                    marginTop: '5px',
                    padding: '0.5rem'
                  }}
                >
                  <div className="flex gap-3 mb-1">
                    <AccountCircleIcon className="bg-white text-slate-700 rounded-full p-1 text-3xl" />
                    <div className="flex flex-col gap-2">
                      <div>has not interacted with a customer over 15 mins.</div>
                      <div className="text-blue-500 cursor-pointer font-bold">View {'->'}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'right'
                    }}
                  >
                    08:02:53 30-01-2024
                  </div>
                </Card>
                <Card
                  className=" rounded-lg bg-slate-700 text-white w-[95%] mx-auto"
                  sx={{
                    marginTop: '5px',
                    padding: '0.5rem'
                  }}
                >
                  <div className="flex gap-3 mb-1">
                    <AccountCircleIcon className="bg-white text-slate-700 rounded-full p-1 text-3xl" />
                    <div className="flex flex-col gap-2">
                      <div>
                        Heavy customer traffic detected at Phone zone. Please re-assign <span className="font-bold">Ritesh Kumar</span> to
                        Phones zone for optimal customer - employee ratio.
                      </div>
                      <div className="text-blue-500 cursor-pointer font-bold">View {'->'}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'right'
                    }}
                  >
                    08:02:53 30-01-2024
                  </div>
                </Card>
                <Card
                  className=" rounded-lg bg-slate-700 text-white w-[95%] mx-auto"
                  sx={{
                    marginTop: '5px',
                    padding: '0.5rem'
                  }}
                >
                  <div className="flex gap-3 mb-1 ">
                    <AccountCircleIcon className="bg-white text-slate-700 rounded-full p-1 text-3xl" />
                    <div className="flex flex-col gap-2">
                      <div>Loyal customer 3330123 detected at entry gate.</div>
                      <div className="text-blue-500 cursor-pointer font-bold">View {'->'}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'right'
                    }}
                  >
                    <p>08:02:53 30-01-2024</p>
                  </div>
                </Card>
              </Card> */}
              <div className=" flex flex-col gap-2 ">
                <Card
                  className="border border-gray-300 h-2/3"
                  style={{
                    padding: '5px',
                    height: 'fit-content'
                  }}
                >
                  <div className="flex items-center">
                    <div>
                      <Chart
                        options={progressChart.options}
                        series={avgCapProgress?.capture_percentage ? [parseFloat(avgCapProgress.capture_percentage).toFixed(1)] : [0]}
                        type={progressChart.options.chart.type}
                        height={progressChart.options.chart.height}
                      />
                    </div>
                    <div className="flex gap-1 flex-col">
                      {/* <div className="text-4xl font-semibold">{capProgressValue ? parseFloat(capProgressValue).toFixed(1) : 0}%</div> */}
                      <div className="text-4xl font-semibold">
                        {avgCapProgress?.capture_percentage ? parseFloat(avgCapProgress?.capture_percentage).toFixed(1) : 0}%
                      </div>
                      <div className="text-sm font-semibold">Capture Progress</div>
                    </div>
                  </div>
                </Card>
                <Card
                  className="border border-gray-300 bg-[#ff413a] h-1/3"
                  style={{
                    padding: '10px',
                    height: '90px'
                  }}
                >
                  <div className="flex w-full h-full">
                    {categoryData.map((item, index) => (
                      <React.Fragment key={item._id}>
                        <div className="w-2/6 h-full flex flex-col">
                          <span className="text-center text-white text-sm font-semibold">OSA</span>
                          {!anomaliesLoading ? (
                            <span className="text-center text-white flex-grow flex flex-col justify-center text-3xl font-semibold">
                              {item.totalEmptyBinCount}
                            </span>
                          ) : (
                            <Skeleton variant="rectangular" height={184} className="rounded-md" />
                          )}
                        </div>
                        <div className="w-2/6 h-full flex flex-col border-2 border-t-0 border-b-0 border-l-white border-r-white">
                          <span className="text-center text-white text-sm font-semibold">Category</span>
                          {!anomaliesLoading ? (
                            <span className="text-center text-white flex-grow flex flex-col justify-center text-3xl font-semibold">
                              {item.totalCategoryAssortmentCount}
                            </span>
                          ) : (
                            <Skeleton variant="rectangular" height={184} className="rounded-md" />
                          )}
                        </div>
                        <div className="w-2/6 h-full flex flex-col">
                          <span className="text-center text-white text-sm font-semibold">Color</span>
                          {!anomaliesLoading ? (
                            <span className="text-center text-white flex-grow flex flex-col justify-center text-3xl font-semibold">
                              {item.totalColorAssortmentCount}
                            </span>
                          ) : (
                            <Skeleton variant="rectangular" height={184} className="rounded-md" />
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview;
