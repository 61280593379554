import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  styled,
  Box,
  RadioGroup,
  Radio
} from '@mui/material';
import { GetClusterManager, GetZones, GetStates, GetCities, GetBrands } from 'api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IoIosClose } from 'react-icons/io';
import { RiLoader5Fill } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

const CustomPopover = styled(Popover)(() => ({
  '& .MuiPaper-root': {
    width: '250px',
    maxHeight: '75vh',
    padding: '10px 0',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '10px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555'
    }
  }
}));

const StyledAccordion = styled(Accordion)(() => ({
  borderBottom: '1px solid lightgrey'
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: '30px',
  '&.Mui-expanded': {
    minHeight: '48px'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px 0',
    '&.Mui-expanded': {
      margin: '12px 0'
    }
  }
}));

const FilterComponent = ({ setFilterOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [firstSelection, setFirstSelection] = useState('');
  const [secondSelection, setSecondSelection] = useState('');
  const [thirdSelection, setThirdSelection] = useState('');
  const [fourthSelection, setFourthSelection] = useState('');
  const [fifthSelection, setFifthSelection] = useState('');
  const [clusterManagers, setClusterManagers] = useState([]);
  const [zones, setZones] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [expanded, setExpanded] = useState(false);
  // eslint-disable-next-line
  const [brands, setBrands] = useState([]);
  const [applyActive, setApplyActive] = useState(false);
  const [currentManagerId, setCurrentManagerId] = useState('');
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.customization.filterOption);
  const ftypeget = useSelector((state) => state.customization.flType);
  console.log('TypeValue is redddddd', ftypeget);
  const theme = useTheme();
  const [type, setType] = useState('All');
  console.log('filterdata', filterData);
  console.log('zone', zones);
  const [clearClicked, setClearClicked] = useState(false);

  useEffect(() => {
    async function firstTime1() {
      // if (filterData) {
      // const userId= localStorage.getItem('userData')
      // const users = JSON.parse(userId);
      const response = await GetClusterManager();
      // const cluster = response?.data;
      // if (filterData.currentManagerId) {
      setClusterManagers(response?.data || []);
      const responseZ = await GetZones({ user_id: filterData.currentManagerId });
      setZones(responseZ?.data?.zones[0].uniqueZones || []);
      const responseS = await GetStates({ user_id: filterData.currentManagerId, zone: filterData.zone });
      setStates(responseS?.data[0].uniqueState || []);
      const responseC = await GetCities({
        user_id: filterData.currentManagerId,
        zone: filterData.zone,
        state: filterData.state
      });
      setCities(responseC?.data || []);
      // // }
    }
    firstTime1();
    // eslint-disable-next-line
  }, [clearClicked]);

  useEffect(() => {
    async function firstTime() {
      if (filterData) {
        const response = await GetClusterManager();
        const cluster = response?.data;
        if (filterData.currentManagerId) {
          console.log('cluster data', cluster);
          setClusterManagers(response?.data || []);
          const managerName = cluster.find((obj) => obj.id === filterData.currentManagerId);
          setFirstSelection(managerName.user_name);
          // if (filterData.zone) {
          const manager = cluster.find((manager) => manager._id === filterData.currentManagerId);
          console.log('rmmm', manager);
          if (manager) {
            setCurrentManagerId(manager._id);
            const response = await GetZones({ user_id: manager._id });
            setZones(response?.data?.zones[0].uniqueZones || []);
            setSecondSelection(filterData.zone);
          }
          if (filterData) {
            //zone
            const responseS = await GetStates({ user_id: manager._id, zone: filterData.zone });
            setStates(responseS?.data[0].uniqueState || []);
            setThirdSelection(filterData.state);
            if (filterData) {
              //state
              const responseC = await GetCities({
                user_id: manager._id,
                zone: filterData.zone,
                state: filterData.state
              });
              setCities(responseC?.data || []);
              setFourthSelection(filterData.city);
            }
          }
        }
        setFilterOptions({
          currentManagerId: filterData.currentManagerId,
          city: filterData.city,
          state: filterData.state,
          zone: filterData.zone,
          theme: filterData.theme
        });
      }
      // handleApply();
    }
    firstTime();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (secondSelection && thirdSelection && fourthSelection) {
    if (firstSelection) {
      setApplyActive(true);
    } else {
      setApplyActive(false);
    }
  }, [firstSelection]);
  // }, [secondSelection, thirdSelection, fourthSelection]);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    const response = await GetClusterManager();
    setClusterManagers(response?.data || []);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    // handleClear();
    setExpanded('');
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFirstChange = async (event) => {
    const selectedManager = event.target.value;
    setFirstSelection(selectedManager);
    setSecondSelection('');
    setThirdSelection('');
    setFourthSelection('');
    setZones([]);
    setStates([]);
    setCities([]);
    setExpanded('secondPanel');

    let manager = '';
    if (selectedManager) {
      manager = clusterManagers.find((manager) => manager.user_name === selectedManager);
      console.log('manager is ', manager);
      setCurrentManagerId(manager._id);
    }
    const response = await GetZones({ user_id: manager._id });

    // const manager = clusterManagers.find((manager) => manager.user_name === selectedManager);
    // if (manager) {
    setZones(response?.data?.zones[0].uniqueZones || []);
    // }
  };

  const handleSecondChange = async (event) => {
    const selectedZone = event.target.value;
    setSecondSelection(selectedZone);
    setThirdSelection('');
    setFourthSelection('');
    setStates([]);
    setCities([]);
    setExpanded('thirdPanel');

    let manager = '';
    if (firstSelection) {
      manager = clusterManagers.find((manager) => manager.user_name === firstSelection);
    }
    // if (manager) {
    const response = await GetStates({ user_id: manager._id, zone: selectedZone });
    setStates(response?.data[0].uniqueState || []);
    console.log('States', response.data);
    // }
  };

  const handleThirdChange = async (event) => {
    const selectedState = event.target.value;
    setThirdSelection(selectedState);
    setFourthSelection('');
    setCities([]);
    setExpanded('fourthPanel');
    let manager = '';
    if (firstSelection) {
      manager = clusterManagers.find((manager) => manager.user_name === firstSelection);
    }
    // if (manager) {
    const response = await GetCities({
      user_id: manager ? manager._id : '',
      zone: secondSelection,
      state: selectedState
    });
    setCities(response?.data || []);
    // }
  };

  const handleFourthChange = (event) => {
    setFourthSelection(event.target.value);
  };

  const fetchBrandsData = async () => {
    try {
      // Fetch brands based on selected parameters
      const brandData = await GetBrands({
        user_id: firstSelection, // Assuming firstSelection is the selected cluster manager
        zone: secondSelection, // Assuming secondSelection is the selected zone
        state: thirdSelection, // Assuming thirdSelection is the selected state
        city: fourthSelection // Assuming fourthSelection is the selected city
      });
      setBrands(brandData || []);
    } catch (error) {
      console.log('Error fetching brands:', error);
    }
  };

  // handleFifthChange function
  // eslint-disable-next-line
  const handleFifthChange = async (event) => {
    const selectedBrandId = event.target.value;
    setFifthSelection(selectedBrandId);

    // Fetch brands based on selected parameters
    await fetchBrandsData();
  };

  const handleClear = () => {
    setClearClicked(!clearClicked);
    setFirstSelection('');
    setSecondSelection('');
    setThirdSelection('');
    setFourthSelection('');
    setFifthSelection('');
    setZones([]);
    setStates([]);
    setCities([]);
    setFilterOptions({
      currentManagerId: '',
      city: '',
      state: '',
      zone: '',
      theme: 'All'
    });
    dispatch({
      type: 'filterOption',
      flOption: {
        currentManagerId: '',
        city: '',
        state: '',
        zone: '',
        theme: 'All'
      }
    });
    setExpanded(false);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
    console.log('TypeValue is', event.target.value);
    localStorage.setItem('ThemeType', event.target.value);
  };

  const handleApply = () => {
    console.log({
      firstSelection,
      secondSelection,
      thirdSelection,
      fourthSelection,
      fifthSelection
    });
    applyActive &&
      setFilterOptions({
        currentManagerId: currentManagerId,
        city: fourthSelection
          ? fourthSelection
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '',
        state: thirdSelection
          ? thirdSelection
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '',
        zone: secondSelection ? secondSelection : '',
        theme: type
      });
    dispatch({
      type: 'flType',
      ftype: type
    });
    dispatch({
      type: 'filterOption',
      flOption: {
        currentManagerId: currentManagerId,
        city: fourthSelection
          ? fourthSelection
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '',
        state: thirdSelection
          ? thirdSelection
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          : '',
        zone: secondSelection ? secondSelection : '',
        theme: type
      }
    });
    handlePopoverClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const isMobile = useMediaQuery('(max-width:600px)');
  const isFilterApplied = !!filterData.currentManagerId;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        sx={{
          border: `1px solid ${isFilterApplied ? theme.palette.primary.dark : 'lightgrey'}`, // Surrounding box
          borderRadius: isMobile ? '4px' : '5px',
          background: isMobile
            ? 'white!important'
            : filterData.currentManagerId
            ? theme.palette.primary.dark + '!important'
            : 'white!important',
          color: filterData.currentManagerId ? 'white' : isMobile ? 'grey' : 'black',
          height: isMobile ? '27px' : '40px',
          padding: isMobile ? '4px' : '7px',
          marginLeft: isMobile ? '8px' : '0'
        }}
        aria-describedby={id}
        onClick={handlePopoverOpen}
      >
        {!isMobile && <span className="mr-6 text-sm">Filter </span>}

        <FilterAltOutlinedIcon
          style={{ color: isMobile && isFilterApplied ? theme.palette.primary.main : isMobile || !isFilterApplied ? 'black' : 'white' }}
        />
      </IconButton>
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
            marginLeft: '10px',
            background: 'white'
          }}
        >
          <Typography variant="h6">Filter Content</Typography>
          <IconButton onClick={handlePopoverClose}>
            <IoIosClose size={24} />
          </IconButton>
        </div>

        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          // defaultValue="All"
          value={type}
          name="radio-buttons-group"
          className="ml-2"
          onChange={handleChangeType}
        >
          <FormControlLabel value="All" control={<Radio />} label="All" />
          <FormControlLabel value="Blush" control={<Radio />} label="Blush" />
          <FormControlLabel value="Lace" control={<Radio />} label="Lace" />
        </RadioGroup>

        <StyledAccordion expanded={expanded === 'firstPanel'} onChange={handleAccordionChange('firstPanel')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Cluster Manager</Typography>
          </StyledAccordionSummary>
          <AccordionDetails className={!clusterManagers.length ? 'flex items-center justify-center' : ''}>
            {!clusterManagers.length ? (
              <RiLoader5Fill className="animate-spin text-4xl text-center" />
            ) : (
              <FormControl component="fieldset">
                {clusterManagers.map((manager) => (
                  <FormControlLabel
                    key={manager.user_name}
                    control={
                      <Checkbox checked={firstSelection === manager.user_name} onChange={handleFirstChange} value={manager.user_name} />
                    }
                    label={manager.user_name}
                  />
                ))}
              </FormControl>
            )}
          </AccordionDetails>
        </StyledAccordion>

        <StyledAccordion expanded={expanded === 'secondPanel'} onChange={handleAccordionChange('secondPanel')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Zones Selection</Typography>
          </StyledAccordionSummary>
          <AccordionDetails className={!zones.length ? 'flex items-center justify-center' : ''}>
            {!zones.length ? (
              <RiLoader5Fill className="animate-spin text-4xl text-center" />
            ) : (
              <FormControl component="fieldset">
                {zones.map((zone, index) => (
                  <FormControlLabel
                    key={zone + index}
                    control={<Checkbox checked={secondSelection === zone} onChange={handleSecondChange} value={zone} />}
                    label={zone}
                  />
                ))}
              </FormControl>
            )}
          </AccordionDetails>
        </StyledAccordion>

        <StyledAccordion expanded={expanded === 'thirdPanel'} onChange={handleAccordionChange('thirdPanel')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>States Selection</Typography>
          </StyledAccordionSummary>
          <AccordionDetails className={!states.length ? 'flex items-center justify-center' : ''}>
            {!states.length ? (
              <RiLoader5Fill className="animate-spin text-4xl text-center" />
            ) : (
              <FormControl component="fieldset">
                {states.map((state, index) => (
                  <FormControlLabel
                    key={state + index}
                    control={<Checkbox checked={thirdSelection === state} onChange={handleThirdChange} value={state} />}
                    label={state}
                  />
                ))}
              </FormControl>
            )}
          </AccordionDetails>
        </StyledAccordion>

        <StyledAccordion expanded={expanded === 'fourthPanel'} onChange={handleAccordionChange('fourthPanel')}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>City Selection</Typography>
          </StyledAccordionSummary>
          <AccordionDetails className={!cities.length ? 'flex items-center justify-center' : ''}>
            {!cities.length ? (
              <RiLoader5Fill className="animate-spin text-4xl text-center" />
            ) : (
              <FormControl component="fieldset">
                {cities.map((city) =>
                  city.uniqueCity.map((uniqueCity, idx) => (
                    <FormControlLabel
                      key={uniqueCity + idx}
                      control={<Checkbox checked={fourthSelection === uniqueCity} onChange={handleFourthChange} value={uniqueCity} />}
                      label={uniqueCity}
                    />
                  ))
                )}
              </FormControl>
            )}
          </AccordionDetails>
        </StyledAccordion>

        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
          <Button onClick={handleClear}>Clear</Button>
          <Button
            onClick={handleApply}
            // disabled={!applyActive}
            variant="contained"
            // className=" text-white rounded-lg"
            sx={{
              bgcolor: theme.palette.primary.main + '!important',
              '&:hover': { bgcolor: theme.palette.primary.dark }
            }}
          >
            Apply
          </Button>
        </div>
      </CustomPopover>
    </Box>
  );
};

export default FilterComponent;
