// action - state management
import * as actionTypes from './actions';

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const initialState = {
  isOpen: 'insights', //for active default menu
  navType: '',
  date: [],
  filterOption: {
    currentManagerId: '',
    city: '',
    state: '',
    zone: '',
    theme: 'All'
  },
  selectedDate: new Date(),
  isLayoutOpen: false,
  flType: 'All'
};

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      return {
        ...state,
        isOpen: action.isOpen
      };
    case actionTypes.MENU_TYPE:
      return {
        ...state,
        navType: action.navType
      };
    case 'DATE':
      return {
        ...state,
        date: action.date
      };
    case 'filterOption':
      return {
        ...state,
        filterOption: action.flOption
      };
    case 'selectedDate':
      return {
        ...state,
        selectedDate: action.payload
      };
    case 'IS_LAYOUT_OPEN':
      return {
        ...state,
        isLayoutOpen: action.payload
      };
    case 'flType':
      return {
        ...state,
        flType: action.ftype
      };
    default:
      return state;
  }
};

export default customizationReducer;
